import {
  ShopOutlined,
  TeamOutlined,
  MenuOutlined,
  UserSwitchOutlined,
  ScissorOutlined,
  VideoCameraOutlined,
  BarChartOutlined,
  MonitorOutlined
} from '@ant-design/icons'
import { MenuProps } from 'pages/staff/interface'
import { MENU_BI } from './BI'
import { MENU_BI_REPORT } from './BI-report'

const MENU_WEBFORM = [
  {
    isParent: true,
    key: 'csvc',
    path: '',
    title: 'CSVC',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/csvc/add',
        path: '/csvc/add',
        title: 'Thêm mới yêu cầu',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/csvc/list',
        path: '/csvc/list',
        title: 'Thống kê sự cố',
        icon: <UserSwitchOutlined />
      },
      {
        isParent: true,
        key: '/csvc/process',
        path: '/csvc/process',
        title: 'Tiến độ hoàn thành',
        icon: <TeamOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'report',
    path: '',
    title: 'Báo cáo',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/report/perfomance-v2',
        path: '/report/perfomance-v2',
        title: 'Năng suất V2',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/report/topup',
        path: '/report/topup',
        title: 'Topup',
        icon: <UserSwitchOutlined />
      },
      {
        isParent: true,
        key: '/report/service',
        path: '/report/service',
        title: 'Dịch vụ',
        icon: <UserSwitchOutlined />
      },
      {
        isParent: true,
        key: '/report/cosmetic',
        path: '/report/cosmetic',
        title: 'Mỹ phẩm',
        icon: <UserSwitchOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'kqkd',
    path: '',
    title: 'BC KQKD',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/kqkd/summary',
        path: '/kqkd/summary',
        title: 'Tóm tắt Báo cáo KQKD V3',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'operation',
    path: '',
    title: 'BC Vận hành',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/operation/report',
        path: '/operation/report',
        title: 'BC Sale - Vận hành V3',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'booking',
    path: '',
    title: 'Booking',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/booking/list',
        path: '/booking/list',
        title: 'Danh sách',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'checkin',
    path: '',
    title: 'Chấm công',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/checkin/daily',
        path: '/checkin/list',
        title: 'Checkin - Hàng ngày',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/checkin/report',
        path: '/checkin/report',
        title: 'Checkin - Báo cáo',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/checkin/cv-staff',
        path: '/checkin/cv-staff',
        title: 'Thống kê hồ sơ nhân sự',
        icon: <MenuOutlined />
      }
    ]
  },

  // {
  //   isParent: true,
  //   key: 'supportLean',
  //   path: '',
  //   title: 'Support-lean',
  //   icon: <TeamOutlined />,
  //   nested: [
  //     {
  //       isParent: true,
  //       key: '/support-lean',
  //       path: '/support-lean',
  //       title: 'support-lean',
  //       icon: <MenuOutlined />
  //     }
  //   ]
  // },
  {
    isParent: true,
    key: 'supervise',
    path: '',
    title: 'Giám sát',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/supervise/tkgs',
        path: '/supervise/tkgs',
        title: 'TKGS',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/supervise/report-error',
        path: '/supervise/report-error',
        title: 'Báo cáo lỗi 2019',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/supervise/add-error',
        path: '/supervise/add-error',
        title: 'Nhập lỗi GS 2019',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'bill',
    path: '',
    title: 'Hóa đơn',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/bill/back',
        path: '/bill/back',
        title: 'Danh sách hàng trả lại',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'employee',
    path: '',
    title: 'Nhân sự',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/employee/skill',
        path: '/employee/skill',
        title: 'Bậc kỹ năng',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/employee/rule',
        path: '/employee/rule',
        title: 'Quy trình/ Quy định',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'shinemember',
    path: '',
    title: 'Đổi ShineMember',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/shinemember/approve',
        path: '/shinemember/approve',
        title: 'Duyệt yêu cầu',
        icon: <MenuOutlined />
      }
    ]
  }
  // {
  //   isParent: true,
  //   key: '/bcCongNo/BcCongNoPage',
  //   path: '/bcCongNo/BcCongNoPage',
  //   title: 'BC công nợ thẻ member',
  //   icon: <TeamOutlined />,
  //   nested: []
  // }
]

const MENU_APPROVE = [
  {
    isParent: true,
    key: 'stylist',
    path: '',
    title: 'Duyệt thông tin Stylist',
    icon: <UserSwitchOutlined />,
    nested: [
      {
        isParent: true,
        key: '/stylist/avatar',
        path: '/stylist/avatar',
        title: 'Duyệt avatar booking',
        icon: <MenuOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/stylist/hair',
        path: '/stylist/hair',
        title: 'Duyệt kiểu tóc sở trường',
        icon: <UserSwitchOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/stylist/introduction',
        path: '/stylist/introduction',
        title: 'Duyệt giới thiệu bản thân',
        icon: <UserSwitchOutlined />,
        authorization: true
      }
    ]
  }
]

const MENU_NOTI = [
  {
    isParent: true,
    key: '/pushNotification',
    path: '',
    title: 'Push notification',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: false,
        key: '/pushNotification',
        path: '/pushNotification',
        title: 'Quản lý noti',
        icon: <MenuOutlined />
      },
      {
        isParent: false,
        key: '/pushNotification/DefaultPushNotification',
        path: '/pushNotification/DefaultPushNotification',
        title: 'Gửi thông báo cố định',
        icon: <MenuOutlined />
      }
    ]
  }
]

const PreMenu: MenuProps[] = [
  {
    isParent: true,
    key: 'staff',
    path: '',
    title: 'Quản lý nhân viên',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/staff/list',
        path: '/staff/list',
        title: 'Danh sách nhân viên',
        icon: <MenuOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/change',
        path: '/change',
        title: 'Biến động nhân sự',
        icon: <UserSwitchOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/staff/add',
        path: '/staff/add',
        title: 'thêm mới nhân sự',
        icon: <MenuOutlined />,
        hidden: true
      },
      {
        isParent: true,
        key: '/staff/detail',
        path: '/staff/detail',
        title: 'sửa thông tin nhân viên',
        icon: <UserSwitchOutlined />,
        hidden: true
      }
    ]
  }
]

const AI: MenuProps[] = [
  {
    isParent: true,
    key: 'ai',
    path: '',
    title: 'AI giám sát',
    icon: <MonitorOutlined />,
    nested: [
      {
        isParent: true,
        key: '/staff/ai-record',
        path: '/staff/ai-record',
        title: 'Bản ghi âm AI',
        icon: <VideoCameraOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/staff/ai-record-statistics',
        path: '/staff/ai-record-statistics',
        title: 'Thống kê sử dụng Mic',
        icon: <BarChartOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/staff/ai-camera',
        path: '/staff/ai-camera',
        title: 'Bản ghi camera AI',
        icon: <VideoCameraOutlined />,
        authorization: true
      }
    ]
  }
]

const MENU_UPLOAD_STYLE = [
  {
    isParent: false,
    key: 'upload-hair-styles',
    path: '/upload-hair-styles',
    title: 'Kiểu tóc mẫu',
    icon: <ScissorOutlined />,
    nested: []
  }
]

const MENU_SALON_PRODUCT_SERVICE = [
  {
    isParent: true,
    key: 'product',
    path: '',
    title: 'Sản phẩm',
    icon: <img src='/icon/product.png' alt='30shine' />,
    nested: [
      {
        isParent: true,
        key: '/product/list',
        path: '/product/list',
        title: 'Danh sách Sản phẩm'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/product/import',
        path: '/product/import',
        title: 'Import giá sản phẩm salon'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/product/config-salon',
        path: '/product/config-salon',
        title: 'Cấu hình sản phẩm salon'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/product/config-member',
        path: '/product/config-member',
        title: 'Giá member sản phẩm'
        // icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'service',
    path: '',
    title: 'Dịch vụ',
    icon: <img src='/icon/services.png' alt='30shine' />,
    nested: [
      {
        isParent: true,
        key: '/service/list',
        path: '/service/list',
        title: 'Danh sách dịch vụ'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/service/import',
        path: '/service/import',
        title: 'Import giá dịch vụ salon'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/service/config-salon',
        path: '/service/config-salon',
        title: 'Cấu hình dịch vụ salon'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/service/config-member',
        path: '/service/config-member',
        title: 'Giá member dịch vụ'
        // icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'salary',
    path: '',
    title: 'Lương',
    icon: <img src='/icon/salary.png' alt='30shine' />,
    nested: [
      {
        isParent: true,
        key: '/salary/salary-service',
        path: '/salary/salary-service',
        title: 'Xem lương dịch vụ'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/salary/config-service',
        path: '/salary/config-service',
        title: 'Cấu hình lương dịch vụ'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/salary/product-salary-list',
        path: '/salary/product-salary-list',
        title: 'Xem tip lương sản phẩm'
        // icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/salary/product-salary-config',
        path: '/salary/product-salary-config',
        title: 'Cấu hình tip lương sản phẩm'
        // icon: <MenuOutlined />
      },
    ]
  },
  {
    isParent: true,
    key: 'salon',
    path: '',
    title: 'Salon',
    icon: <ShopOutlined />,
    nested: [
      {
        isParent: true,
        key: '/salon/list',
        path: '/salon/list',
        title: 'Danh sách Salon',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/salon/service',
        path: '/salon/service',
        title: 'Dịch vụ Salon',
        icon: <MenuOutlined />
      }
    ]
  }
]

const MENU_STAFF = [
  {
    isParent: false,
    key: '/support-lean',
    path: '/support-lean',
    title: 'support-lean',
    icon: <TeamOutlined />,
    nested: []
  },
  {
    isParent: false,
    key: '/department-configuration',
    path: '/department-configuration',
    title: 'Cấu hình phòng ban',
    icon: <TeamOutlined />,
    nested: []
  },
  {
    isParent: true,
    key: 'quality',
    path: '',
    title: 'Chất lượng',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: false,
        key: '/quality/check-scsc/list',
        path: '/quality/check-scsc/list',
        title: 'Check SCSC',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/quality/check-scsc/list/admin',
        path: '/quality/check-scsc/list/admin',
        title: 'Xem SCSC Admin',
        icon: <MenuOutlined />
      }
    ]
  }
]

const MENU_Inventory = [
  {
    isParent: true,
    key: '/requestOrder/list',
    path: '',
    title: 'Duyệt đơn order từ NCC',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/requestOrder/list',
        path: '/requestOrder/list',
        title: 'Danh sách đơn order',
        icon: <MenuOutlined />
      }
    ]
  }
]

const MENU_BC_CONG_NO = [
  {
    isParent: false,
    key: '/bcCongNo/BcCongNoPage',
    path: '/bcCongNo/BcCongNoPage',
    title: 'Báo cáo công nợ thẻ member',
    icon: <ScissorOutlined />,
    nested: []
  }
]

const MENU_CHANGE_SHINE_MEMBER = [
  {
    isParent: false,
    key: '/change-shine-member',
    path: '/change-shine-member',
    title: 'Đổi Shine Member',
    icon: <ScissorOutlined />,
    nested: []
  }
]

const MENU_STAFF_SALARY_LEVEL = [
  {
    isParent: true,
    key: '/staff-skill-salary',
    path: '/staff-skill-salary',
    title: 'Bậc/Level lương',
    icon: <ScissorOutlined />,
    nested: [
      {
        isParent: true,
        key: '/staff-skill-salary/basic-salary',
        path: '/staff-skill-salary/basic-salary',
        title: 'Danh sách lương cơ bản'
      },
      {
        isParent: true,
        key: '/staff-skill-salary/config-basic-salary',
        path: '/staff-skill-salary/config-basic-salary',
        title: 'Import cấu hình lương cơ bản'
      },
      {
        isParent: true,
        key: '/staff-skill-salary/skill-level-list',
        path: '/staff-skill-salary/skill-level-list',
        title: 'Danh sách bậc lương nhân sự'
      },
      {
        isParent: true,
        key: '/staff-skill-salary/config-staff-skill-level',
        path: '/staff-skill-salary/config-staff-skill-level',
        title: 'Import lên bậc nhân sự'
      },
    ]
  },
]
const allMenus = [
  ...PreMenu,
  ...AI,
  ...MENU_APPROVE,
  ...MENU_BI,
  ...MENU_BI_REPORT,
  ...MENU_SALON_PRODUCT_SERVICE,
  ...MENU_UPLOAD_STYLE,
  ...MENU_STAFF,
  ...MENU_Inventory,
  ...MENU_NOTI,
  ...MENU_BC_CONG_NO,
  ...MENU_STAFF_SALARY_LEVEL,
  ...MENU_CHANGE_SHINE_MEMBER
]
export const MENUS = ['staging', 'production', 'test'].includes(process.env.REACT_APP_ENV)
  ? allMenus
  : [...allMenus, ...MENU_WEBFORM]

