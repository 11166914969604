import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import Page404 from '../pages/errors/404'
import Page403 from '../pages/errors/403'
import Page500 from '../pages/errors/500'
import ScrollToTop from 'components/SrollToTop'
import Loading from '../components/Loading'
import StaffComponent from 'pages/staff/list/page'
import { AddStaff } from 'pages/staff/add/page'
import Staff from 'pages/staff/detail/page'
import ChangeSkillLevel from 'pages/staff/changeSkillLevel'
import { ListAIRecord } from 'pages/AIRecord/list'
import { AiCameraList } from 'pages/AiCamera/list'
import { AIRecordStatistics } from 'pages/AIRecord/statistics/index'
import StaffChangeComponent from 'pages/staff/change/page'
import { useAppSelector } from 'redux/store'
import StylistAvatarComponent from 'pages/stylist/avatar/page'
import StylistHairComponent from 'pages/stylist/hair/page'
import { BaseListPage } from 'pages/base'
import BcCongNoPageMember from 'pages/bcCongNo/BcCongNoPage'
import StylistIntroductionComponent from 'pages/stylist/introduction/page'
import ReportRouterTopup from '../pages/Report/router'
import { routerSupervise } from 'pages/supervise/router'
import { routerBill } from 'pages/bill/router'
import { routerCheckin } from 'pages/checkin/router'
// import { routerProduct } from 'pages/product/router'
import { routerBooking } from 'pages/booking/routerStore'
import { routerCSVC } from 'pages/csvc/router'
// import ShineMemberRouter from '../pages/ShineMember/router'

// import ProductsRouter from 'pages/Products/router'
// import ServiceRouter from 'pages/Service/router'
import ReportRouter from 'pages/reportVanHanhV3/router'
import EmployeeRouter from 'pages/Employee/router'
// import { routerSalon } from 'pages/Salon/router'
import { routerKQKD } from 'pages/kqkd/router'
import StylistSelectHair from 'pages/stylist/webview/selectHair'
import StylistSelectIntroduction from 'pages/stylist/webview/selectIntroduction'
import UploadHairRouter from 'pages/UploadHairStyles/router'
import { ExportDataPage } from 'pages/BI/ExportDataPage'
import { CreateScriptPage } from 'pages/BI/CreateScript'
import { UpdateScriptPage } from 'pages/BI/UpdateScript'

import { List } from 'pages/Salon/List'
import { ServicesSalonConfig } from 'pages/Salon/Services'
import { Partitions } from 'pages/Salon/Shrink'
import { SalonAddPage } from 'pages/Salon/List/add'
import { SalonDetailtPage } from 'pages/Salon/List/detailt'

import { ServiceListPage } from 'pages/Service/list'
import { AddServicePage } from 'pages/Service/list/add'
import { DetailService } from 'pages/Service/list/detail'
import { AddConfigServicePage } from 'pages/Service/config/add'
// càn màn bo sung sau
import { ServiceImportPriceSalon } from 'pages/Service/import'
import { ServiceConfigProductSalon } from 'pages/Service/config/ProductSalon'
import { ServiceConfigMemberPrice } from 'pages/Service/config/MemberPrice'

import { ProductImportPriceSalon } from 'pages/Products/import/PriceSalon'
import { ProductConfigSalon } from 'pages/Products/config/ConfigProductSalon'
import { ProductConfigPriceMember } from 'pages/Products/config/PriceMember'

// lương
import { SalaryConfigService } from 'pages/Salary/config/SalaryService'
import { ListSalaryService } from 'pages/Salary/list/SalaryService'
import ProductSalaryConfig from 'pages/SalaryProduct/config/ProductSalaryConfig'
import ProductSalaryConfigList from 'pages/SalaryProduct/list/ProductSalaryConfigList'
import BasicSalary from 'pages/StaffBasicSalary/BasicSalaryList'
import StaffSkillLevelList from 'pages/StaffSkillSalary/StaffSkillList'
import AddStaffSkillLevel from 'pages/StaffSkillSalary/StaffSkillList/components/addNewLevel'
import EditStaffSkillLevel from 'pages/StaffSkillSalary/StaffSkillList/components/editStaffLevel'
import ConfigStaffSkillLevel from 'pages/StaffSkillSalary/ConfigStaffLevel'
import ConfigBasicSalary from 'pages/StaffBasicSalary/ConfigBasicSalary'

// ----------------------------
import { ListProduct } from 'pages/Products/list'
import { ProductConfiguration } from 'pages/Products/ProductConfiguration'
import { AddProductsPage } from 'pages/Products/AddProducts'
import { DetailProductsPage } from 'pages/Products/DetailProducts'
import { SupportLean } from 'pages/SupportLean'
import CheckSCSC from 'pages/quality/pages/detail/scsc'
import ListCheckSCSC from 'pages/quality/pages/list/listSCSC'
import ListAdminCheckSCSC from 'pages/quality/pages/adminList'
import DefaultPushNotification from 'pages/pushNotification/defaultPushNoti'
import ListNotification from 'pages/pushNotification/listNoti'
import DepartmentConfiguration from 'pages/DepartmentConfiguration'

import { ListRerquestOrder } from 'pages/RequestOrder/List'
import { RerquestOrderDetail } from 'pages/RequestOrder/Detail'
// BI report
import { BIReportServicePage } from 'pages/BI-report/service'
import { BIReportCosmeticPage } from 'pages/BI-report/cosmetic'
import { BIReportShineMemberPage } from 'pages/BI-report/shine-member'
import { BIReportTopupPage } from 'pages/BI-report/topup'
import { BIReportReturnedProductPage } from 'pages/BI-report/returned-product'
import { BIReportPerformancePage } from 'pages/BI-report/perfomance'
import { BIReportSubmitRevenuePage } from 'pages/BI-report/submit-revenue'
import { BIReportBusinessResultsPage } from 'pages/BI-report/business-results'
// 
import ChangeShineMember from 'pages/changeShineMember'


const AuthorizationPage = (element, url: string) => {
  const urlAccess = useAppSelector((state) => state.user.urlAccess)
  if (urlAccess) {
    return urlAccess.find((i) => i.path === url) ? element : <Page403 />
  }
}
function MainRouter() {
  const routes = useRoutes([
    // trang staff
    {
      path: '/staff/list',
      element: AuthorizationPage(<StaffComponent />, '/staff/list')
    },
    {
      path: '/staff/ai-record',
      element: AuthorizationPage(<ListAIRecord />, '/staff/ai-record')
    },
    {
      path: '/staff/ai-record-statistics',
      element: AuthorizationPage(<AIRecordStatistics />, '/staff/ai-record-statistics')
    },
    {
      path: '/staff/ai-camera',
      element: AuthorizationPage(<AiCameraList />, '/staff/ai-camera')
    },
    {
      path: '/staff/add',
      element: AuthorizationPage(<AddStaff />, '/staff/add')
    },
    {
      path: '/staff/detail/:id',
      element: AuthorizationPage(<Staff />, '/staff/detail')
    },
    {
      path: '/staff/change-staff-skill-level/:id',
      element: AuthorizationPage(<ChangeSkillLevel />, '/staff/change-staff-skill-level/:id')
    },
    {
      path: '/change',
      element: AuthorizationPage(<StaffChangeComponent />, '/change')
    },
    //noti
    {
      path: '/pushNotification',
      element: AuthorizationPage(<ListNotification />, '/pushNotification')
    },
    {
      path: '/pushNotification/DefaultPushNotification',
      element: AuthorizationPage(<DefaultPushNotification />, '/pushNotification/DefaultPushNotification')
    },
    {
      path: '/pushNotification/DefaultPushNotification/:id',
      element: AuthorizationPage(<DefaultPushNotification />, '/pushNotification/DefaultPushNotification')
    },
    //
    {
      path: '/stylist/avatar',
      element: AuthorizationPage(<StylistAvatarComponent />, '/stylist/avatar')
    },
    {
      path: '/stylist/hair',
      element: AuthorizationPage(<StylistHairComponent />, '/stylist/hair')
    },
    {
      path: '/stylist/introduction',
      element: AuthorizationPage(<StylistIntroductionComponent />, '/stylist/introduction')
    },
    {
      path: '/bi/export',
      element: AuthorizationPage(<ExportDataPage />, '/bi/export')
    },
    {
      path: '/bi/update',
      element: AuthorizationPage(<UpdateScriptPage />, '/bi/update')
    },
    {
      path: '/bi/create',
      element: AuthorizationPage(<CreateScriptPage />, '/bi/create')
    },
    // BI report
    {
      path: '/bi-report/doanh-thu-dich-vu',
      element: AuthorizationPage(<BIReportServicePage />, '/bi-report/doanh-thu-dich-vu')
    },
    {
      path: '/bi-report/doanh-thu-my-pham',
      element: AuthorizationPage(<BIReportCosmeticPage />, '/bi-report/doanh-thu-my-pham')
    },
    {
      path: '/bi-report/doanh-thu-shine-member',
      element: AuthorizationPage(<BIReportShineMemberPage />, '/bi-report/doanh-thu-shine-member')
    },
    {
      path: '/bi-report/doanh-thu-topup',
      element: AuthorizationPage(<BIReportTopupPage />, '/bi-report/doanh-thu-topup')
    },
    {
      path: '/bi-report/hang-doi-tra',
      element: AuthorizationPage(<BIReportReturnedProductPage />, '/bi-report/hang-doi-tra')
    },
    {
      path: '/bi-report/nang-suat',
      element: AuthorizationPage(<BIReportPerformancePage />, '/bi-report/nang-suat')
    },
    {
      path: '/bi-report/nop-doanh-thu',
      element: AuthorizationPage(<BIReportSubmitRevenuePage />, '/bi-report/nop-doanh-thu')
    },
    {
      path: '/bi-report/ket-qua-kinh-doanh',
      element: AuthorizationPage(<BIReportBusinessResultsPage />, '/bi-report/ket-qua-kinh-doanh')
    },
    //---------------------------------------------------------------
    // phần salon
    {
      path: '/salon/list',
      element: AuthorizationPage(<List />, '/salon/list')
      // element: <List />
    },
    {
      path: '/salon/list/add',
      element: AuthorizationPage(<SalonAddPage />, '/salon/list/add')
      // element: <SalonAddPage />
    },
    {
      path: '/salon/list/:id',
      element: AuthorizationPage(<SalonDetailtPage />, '/salon/list/:id')
      // element: <SalonDetailtPage />
    },
    {
      path: '/salon/service',
      element: AuthorizationPage(<ServicesSalonConfig />, '/salon/service')
      // element: <ServicesSalonConfig />
    },
    {
      path: '/salon/shrink',
      element: AuthorizationPage(<Partitions />, '/salon/shrink')
      // element: <Partitions />
    },
    //---------------------------------------------------------------
    // phần dịch vụ
    {
      path: '/service/list',
      element: AuthorizationPage(<ServiceListPage />, '/service/list')
      // element: <ServiceListPage />
    },
    {
      path: '/service/list/add',
      element: AuthorizationPage(<AddServicePage />, '/service/list/add')
      // element: <AddServicePage />
    },
    {
      path: '/service/config/add',
      element: AuthorizationPage(<AddConfigServicePage />, '/service/config/add')
      // element: <AddConfigServicePage />
    },
    {
      path: '/service/config-salon',
      element: AuthorizationPage(<ServiceConfigProductSalon />, '/service/config-salon')
      // element: <AddConfigServicePage />
    },
    {
      path: '/service/config-member',
      element: AuthorizationPage(<ServiceConfigMemberPrice />, '/service/config-member')
      // element: <AddConfigServicePage />
    },
    {
      path: '/service/import',
      element: AuthorizationPage(<ServiceImportPriceSalon />, '/service/import')
      // element: <DetailService />
    },
    {
      path: '/service/detail/:id',
      element: AuthorizationPage(<DetailService />, '/service/detail/:id')
      // element: <DetailService />
    },
    //---------------------------------------------------------------
    // phần sản phẩm
    {
      path: '/product/list',
      element: AuthorizationPage(<ListProduct />, '/product/list')
      // element: <ListProduct />
    },
    {
      path: '/product/config',
      element: AuthorizationPage(<ProductConfiguration />, '/product/config')
      // element: <ProductConfiguration />
    },
    {
      path: '/product/add',
      element: AuthorizationPage(<AddProductsPage />, '/product/add')
      // element: <AddProductsPage />
    },
    {
      path: '/product/detail/:id',
      element: AuthorizationPage(<DetailProductsPage />, '/product/detail/:id')
      // element: <DetailProductsPage />
    },
    {
      path: '/product/import',
      element: AuthorizationPage(<ProductImportPriceSalon />, '/product/import')
      // element: <DetailProductsPage />
    },
    {
      path: '/product/config-salon',
      element: AuthorizationPage(<ProductConfigSalon />, '/product/config-salon')
      // element: <DetailProductsPage />
    },
    {
      path: '/product/config-member',
      element: AuthorizationPage(<ProductConfigPriceMember />, '/product/config-member')
      // element: <DetailProductsPage />
    },
    // ------------------------------------
    // phần lương
    {
      path: '/salary/salary-service',
      element: AuthorizationPage(<ListSalaryService />, '/salary/salary-service')
      // element: <DetailProductsPage />
    },
    {
      path: '/salary/config-service',
      element: AuthorizationPage(<SalaryConfigService />, '/salary/config-service')
      // element: <DetailProductsPage />
    },
    {
      path: '/salary/product-salary-list',
      element: AuthorizationPage(<ProductSalaryConfigList />, '/salary/product-salary-list')
      // element: <DetailProductsPage />
    },
    {
      path: '/salary/product-salary-config',
      element: AuthorizationPage(<ProductSalaryConfig />, '/salary/product-salary-config')
      // element: <DetailProductsPage />
    },
    // 
    {
      path: '/staff-skill-salary/basic-salary',
      element: AuthorizationPage(<BasicSalary />, '/staff-skill-salary/basic-salary')
    },

    {
      path: '/staff-skill-salary/config-basic-salary',
      element: AuthorizationPage(<ConfigBasicSalary />, '/staff-skill-salary/config-basic-salary')
    },
    {
      path: '/staff-skill-salary/skill-level-list',
      element: AuthorizationPage(<StaffSkillLevelList />, '/staff-skill-salary/skill-level-list')
    },
    {
      path: '/staff-skill-salary/skill-level-list/add',
      element: AuthorizationPage(<AddStaffSkillLevel />, '/staff-skill-salary/skill-level-list/add')
    },
    {
      path: '/staff-skill-salary/skill-level-list/:id',
      element: AuthorizationPage(<EditStaffSkillLevel />, '/staff-skill-salary/skill-level-list/:id')
    },
    {
      path: '/staff-skill-salary/config-staff-skill-level',
      element: AuthorizationPage(<ConfigStaffSkillLevel />, '/staff-skill-salary/config-staff-skill-level')
    },
    // {
    //   path: '/staff-skill-salary/config-basic-salary',
    //   element: AuthorizationPage(<ConfigBasicSalary />, '/salary/config-basic-salary')
    // },
    // ------------------------------------
    {
      path: '/stylist/webview/selectHair',
      element: <StylistSelectHair />
    },
    {
      path: '/stylist/webview/selectIntroduction',
      element: <StylistSelectIntroduction />
    },
    {
      path: '/base',
      element: <BaseListPage />
    },
    {
      path: '/operation/*',
      element: <ReportRouter />
    },
    {
      path: '/employee/*',
      element: <EmployeeRouter />
    },
    {
      path: '/report/*',
      element: <ReportRouterTopup />
    },
    {
      path: '/upload-hair-styles/*',
      // element: <UploadHairRouter />
      element: AuthorizationPage(<UploadHairRouter />, '/upload-hair-styles/*')
    },
    {
      path: '/support-lean',
      element: AuthorizationPage(<SupportLean />, '/support-lean')
    },
    {
      path: '/quality/check-scsc/:id',
      element: <CheckSCSC />
    },
    {
      path: '/quality/check-scsc/list',
      element: AuthorizationPage(<ListCheckSCSC />, '/quality/check-scsc/list')
    },
    {
      path: '/quality/check-scsc/list/admin',
      element: AuthorizationPage(<ListAdminCheckSCSC />, '/quality/check-scsc/list/admin')
    },
    {
      path: '/department-configuration',
      element: AuthorizationPage(<DepartmentConfiguration />, '/department-configuration')
    },
    {
      path: '/change-shine-member',
      element: AuthorizationPage(<ChangeShineMember />, '/change-shine-member')
    },
    // {
    //   path: '/shinemember/*',
    //   element: <ShineMemberRouter />
    // },
    {
      path: '/403',
      element: <Page403 />
    },
    {
      path: '/404',
      element: <Page404 />
    },
    {
      path: '/500',
      element: <Page500 />
    },
    ...routerCheckin,
    ...routerSupervise,
    // ...routerProduct,
    ...routerBooking,
    ...routerCSVC,
    ...routerBill,
    ...routerKQKD,
    {
      path: '/requestOrder/list',
      element: <ListRerquestOrder />
      // element: AuthorizationPage(<ListRerquestOrder />, '/admin/bao-cao-tien-do.html')
    },
    {
      path: '/requestOrder/detail/:id',
      element: <RerquestOrderDetail />
      // element: AuthorizationPage(<RerquestOrderDetail />, '/admin/bao-cao-tien-do.html')
    },
    {
      path: '/bcCongNo/BcCongNoPage',
      // element: <BcCongNoPageMember />
      element: AuthorizationPage(<BcCongNoPageMember />, '/bcCongNo/BcCongNoPage')
    }
  ])
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </>
  )
}

export default MainRouter
