export const LIST_ERROR_CHECK_CUT = [
    {
        id: 1,
        group: 'Lỗi hình khối',
        value: [
            {
                id: 1,
                text: 'Cắt đều và cân đối 2 bên mai, phía sau gáy.',
            },
            {
                id: 2,
                text: 'Sấy rà soát lỗi và chuốt mịn phần tóc bung 2 bên Side và bề mặt sau gáy.',
            },
            {
                id: 3,
                text: 'Cạo viền trán, gáy đều và cân đối.',
            },
            {
                id: 4,
                text: 'Kê lược đều tay, cân đối 2 bên Side và bề mặt sau gáy.',
            }
        ]
    },
    {
        id: 2,
        group: 'Lỗi liên kết',
        value: [
            {
                id: 5,
                text: 'Lưu ý cắt, tỉa có độ bám đuổi đều.',
            },
            {
                id: 6,
                text: 'Lưu ý góc độ nâng mảng tóc chính xác và đều.',
            },
            {
                id: 7,
                text: 'Lưu ý khuôn viền phần mái đều.',
            },
            {
                id: 8,
                text: 'Lưu ý tỉa đều tay, mặt cắt tỉa đều, mịn.',
            }
        ]
    },
    {
        id: 3,
        group: 'Lỗi đường nét',
        value: [
            {
                id: 9,
                text: 'Cạo viền tròn, sắc nét.',
            },
            {
                id: 10,
                text: 'Lưu ý mặt cắt có độ chuyển màu đều, mịn.',
            },
            
        ]
    },
    {
        id: 4,
        group: 'Lỗi hoàn thiện',
        value: [
            {
                id: 11,
                text: 'Sấy kỹ, đều tay, có độ phồng, đều.',
            },
        ]
    },
    {
        id: 100,
        group: 'Lỗi ngọn tóc',
        value: [
            {
                id: 12,
                text: 'Quấn lô đều tay, đúng góc độ.',
            },
            {
                id: 13,
                text: 'Canh chỉnh thời gian phù hợp với chất tóc.',
            },
            {
                id: 14,
                text: 'Lựa chọn lô uốn phù hợp với kiểu tóc, chất tóc.',
            },
        ]
    },
    {
        id: 101,
        group: 'Lỗi sóng tóc',
        value: [
            {
                id: 15,
                text: 'Quấn lô đều tay, đúng góc độ.',
                
            },
            {
                id: 16,
                text: 'Canh chỉnh thời gian phù hợp với chất tóc.',
                
            },
            {
                id: 17,
                text: 'Lựa chọn lô uốn phù hợp với kiểu tóc, chất tóc.',
               
            },
        ]
    },
    {
        id: 102,
        group: 'Lỗi chân tóc',
        value: [
            {
                id: 18,
                text: 'Bôi thuốc cách chân 1cm.',
               
            },
            {
                id: 19,
                text: 'Quấn chặt tay, đúng góc độ.',
            },
            {
                id: 20,
                text: 'Massa chân tóc, trước khi xả.',
            },
        ]
    }
]

export const LIST_ERROR_CHECK_CURL = [
    {
        id: 1,
        group: 'Lỗi chụp ảnh thiếu/mờ/lệch/không đúng thiết kế',
        value: [
            {
                id: 1,
                text: 'Chụp đúng tiêu chí SCSC, đúng góc độ.',
               
            },
            {
                id: 2,
                text: 'Chụp đủ ảnh: trái, phải, phía sau, ảnh freeStylist.',
                
            },
            {
                id: 3,
                text: 'Không chụp ngược sáng, không đủ sáng, rung tay.',
            },
            {
                id: 4,
                text: 'Bỏ khăn gội, rửa sạch kem lột mụn.',
            },
        ]
    }
]