/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Collapse, Modal, Slider, Spin, notification } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { Stage, Layer, Circle, Image as KonvaImage, Group } from 'react-konva'
import axios from 'axios'
import Cookies from 'js-cookie'
import './index.scss'
import { useMediaQuery } from 'react-responsive'
import TextArea from 'antd/es/input/TextArea'
import { CheckOutlined, MinusOutlined, PictureFilled, PictureOutlined, PictureTwoTone, PlusOutlined, RollbackOutlined, UndoOutlined } from '@ant-design/icons'
import { postImageError, refreshImageError } from 'pages/quality/api/api'
import { useParams } from 'react-router-dom'
import { LIST_ERROR_CHECK_CUT, LIST_ERROR_CHECK_CURL } from './common'
import Konva from 'konva';

interface Props {
  selectedImage: string
  selectedImageOrder: number
  setListDefaultImage: any
  listDefaultImage: any
  ListImageNote: any
  setListDefaultNote: any
  isCutImage: boolean
  imageCurl: string
  billId: number
  setSelectedImage: any
  setListDefaultImageCurl: any
  setCheckImageError: any
  setCheckDone: any
  checkDone: any
  setCheckedImageError: any
  imageError: number
  imageErrorCurling: number,
  setCountImageErrorCut: any,
  countImageErrorCut: number,
  setCountImageErrorCurl: any,
  countImageErrorCurl: number,
  setIsNotEnoughCheckedCut?: any,
  setIsNotEnoughCheckedCurl?: any,
}

function dataURLtoBlob(dataURL) {
  const binary = atob(dataURL.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' })
}

const CheckError = (props: Props) => {
  const {
    selectedImage,
    selectedImageOrder,
    setListDefaultImage,
    listDefaultImage,
    ListImageNote,
    setListDefaultNote,
    isCutImage,
    imageCurl,
    billId,
    setSelectedImage,
    setListDefaultImageCurl,
    setCheckImageError,
    setCheckDone,
    checkDone,
    setCheckedImageError,
    imageError,
    imageErrorCurling,
    setCountImageErrorCut,
    countImageErrorCut,
    setCountImageErrorCurl,
    countImageErrorCurl,
    setIsNotEnoughCheckedCut,
    setIsNotEnoughCheckedCurl,
  } = props
  const { id } = useParams();
  const { Panel } = Collapse;
  const [circles, setCircles] = useState([])
  const [activeCircleId, setActiveCircleId] = useState(null)
  const [draggingCircleId, setDraggingCircleId] = useState(null)
  const [image, setImage] = useState(null)
  const [fittedDimensions, setFittedDimensions] = useState({ width: 0, height: 0 })
  const stageRef = useRef(null)
  const modalRef = useRef(null)
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const [containerWidth, setContainerWidth] = useState(null)
  const [containerHeight, setContainerHeight] = useState(null)
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [isChange, setIsChange] = useState<boolean>(false)
  const [isResetImage, setIsResetImage] = useState<boolean>(false)
  const [listError, setListError] = useState<any>([])
  const [listMakeGood, setListMakeGood] = useState<any[]>([]);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [lockDrag, setLockDrag] = useState<boolean>(false);

  useEffect(() => {
    if (selectedImageOrder && ListImageNote) {
      const listNote = ListImageNote.find((item: any) => item.order === selectedImageOrder);
      if (listNote) {
        setNote(listNote.error)
        if (listNote.makeGood) {
          const listMakeGood = [];
          listNote.makeGood.forEach((group) => {
            group.value.forEach((item) => {
              listMakeGood.push(item);
            });
          });
          setListMakeGood(listMakeGood)
        }
      }
    }

  }, [selectedImageOrder]);


  const handleSelected = (item) => {
    const newList = [...listMakeGood];
    const index = newList.findIndex((i) => i.id === item.id);
    if (index === -1) {
      newList.push(item);
    } else {
      newList.splice(index, 1);
    }
    setListMakeGood(newList);
  }

  useEffect(() => {
    if (isCutImage) {
      if (imageError) {
        setListError(LIST_ERROR_CHECK_CURL)
      } else {
        setListError(LIST_ERROR_CHECK_CUT)
      }
    } else {
      if (imageErrorCurling) {
        setListError(LIST_ERROR_CHECK_CURL)
      } else {
        setListError(LIST_ERROR_CHECK_CUT)
      }
    }
  }, [isCutImage, imageErrorCurling, imageError])


  useEffect(() => {
    if (selectedImageOrder && checkDone) {
      setIsChange(false)
    }
  }, [selectedImageOrder, checkDone])

  // lấy thông tin kích thước modal
  useEffect(() => {
    setContainerWidth(modalRef.current.clientWidth)
    setContainerHeight(500)
  }, [modalRef])

  // thêm note ảnh mặc định
  useEffect(() => {
    if (ListImageNote && selectedImageOrder <= 5) {
      setNote(ListImageNote?.find((item: any) => item?.order === selectedImageOrder)?.error || '')
    }
  }, [ListImageNote, selectedImageOrder])
  // set ảnh
  useEffect(() => {
    if (selectedImage && containerWidth && containerHeight) {
      const img = new window.Image()
      img.crossOrigin = 'Anonymous'
      img.src = selectedImage
        .toString()
        .replace('storage.30shine.com', 's3.ap-southeast-1.amazonaws.com/storage.30shine.com')
      // img.src = 'https://storage.30shine.com/customer-msa/history/4540433/3a6cd7fd-da8a-4743-a1eb-4b421c57bd2a.jpg'
      img.onload = () => {
        setImage(img)
        const { width, height } = getFittedDimensions(img.width, img.height, containerWidth, containerHeight)
        setFittedDimensions({ width, height })
      }
      img.onerror = () => {
        console.log('Ảnh không hiển thị được')
      }
    }
  }, [selectedImage, containerWidth, containerHeight])


  // thêm khoanh lỗi & điều chỉnh khoanh lỗi
  const handleAddCircle = () => {

    const id = circles.length > 0 ? circles[circles.length - 1].id + 1 : 0
    setCircles([...circles, { id, x: fittedDimensions.width / 2, y: fittedDimensions.height / 2, radius: isMobile ? 20 : 30 }])
    setIsChange(true)
    setActiveCircleId(id)
  }

  const handleRemoveLastCircle = () => {
    setCircles(circles.slice(0, -1))

  }

  const handleMouseEnter = (id) => {
    // console.log(`handleMouseEnter ${id}`);

    setActiveCircleId(id);
    setLockDrag(true);
  }

  const handleMouseLeave = () => {
    // setActiveCircleId(null)
    setLockDrag(false);
  }

  const handleCircleTouchStart = (e, id) => {
    // console.log(`handleTouchStart ${id}`);
    setActiveCircleId(id);
    setLockDrag(true);
  }

  const handlePlus = () => {
    // console.log(`handlePlus ${activeCircleId}`);

    setCircles((circles) => circles.map((circle) => (circle.id === activeCircleId ? { ...circle, radius: circle.radius + 1 } : circle)))
  }

  const handleMinus = () => {
    // console.log(`handleMinus ${activeCircleId}`);
    setCircles((circles) => circles.map((circle) => (circle.id === activeCircleId ? { ...circle, radius: circle.radius - 1 } : circle)))
  }


  const handleCircleTouchEnd = () => {
    // setDraggingCircleId(null)
    setLockDrag(false);
  }

  // thay đổi note
  const handleChangeNote = (note) => {
    setNote(note)
    setIsChange(true)
  }

  // Lấy ảnh cũ chưa chấmsam
  const handleRefreshImage = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await refreshImageError({
        billId,
        order: selectedImageOrder
      })
      if (response.statusCode === 200) {
        setSelectedImage(response.data)
        setCircles([])
        setIsResetImage(true)
        setIsChange(true)
        notification.success({
          message: 'Thông báo!',
          description: <>Lấy ảnh cũ thành công.</>
        })
      } else {
        notification.error({
          message: 'Thông báo!',
          description: <>Lấy ảnh cũ thất bại.</>
        })
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Thông báo!',
        description: <>Lấy ảnh cũ thất bại.</>
      })
      setLoading(false)
      console.log(error, 'error')
    }
  }

  // Ấn nút lưu ảnh
  const handleSaveImage = () => {

    if (loading) return
    setLoading(true)
    const canvas = stageRef.current.toCanvas()
    try {
      //ảnh ko được khoanh
      if (circles.length === 0) {
        uploadErrorImage(selectedImage)
      } else {
        console.log('listMakeGood', listMakeGood);

        //ảnh được khoanh
        if (listMakeGood?.length === 0 && !note) {
          setLoading(false)
          notification.error({
            message: 'Thông báo!',
            description: <>Vui lòng chọn phương án xử lý hoặc nhập phương án xử lý khác.</>
          })
          return
        } else {
          const dataURL = canvas.toDataURL('image/png')
          const blob = dataURLtoBlob(dataURL)
          uploadImage(blob)
        }
      }
    } catch (error) {
      setLoading(false)
      console.error(error, 'Không lưu được ảnh')
      notification.error({
        message: 'Thông báo!',
        description: <>Không lưu được ảnh</>
      })
      throw error
    }

  }

  // gửi ảnh lên s3 lấy link
  const uploadImage = async (blob: Blob) => {
    const AccessToken = Cookies.get('AccessToken') || ''
    try {
      const formData = new FormData()
      formData.append('photo', blob, 'resized.jpg')
      const axiosResponse = await axios.post(`${process.env.REACT_APP_UPLOAD_STAFF}/staff/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AccessToken}`
        }
      })
      if (axiosResponse?.data?.data?.image_url) {
        uploadErrorImage(axiosResponse.data.data.image_url)
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Thông báo!',
        description: <>Không lưu được ảnh</>
      })
      setLoading(false)
      throw error
    }
  }

  // call api cập nhật ảnh mới và note
  const uploadErrorImage = async (img) => {
    const AccessToken = Cookies.get('AccessToken') || ''
    let newListImageNote
    const newListMakeGood = listError.map(group => {
      const filteredValues = group.value.filter(item => listMakeGood.some(a => a.id === item.id));
      return filteredValues.length > 0 ? { ...group, value: filteredValues } : null;
    }).filter(group => group !== null);

    if (ListImageNote) {
      newListImageNote = [...ListImageNote]
      const existingImageNoteIndex = newListImageNote.findIndex((item) => item.order === selectedImageOrder)

      if (existingImageNoteIndex !== -1) {
        newListImageNote[existingImageNoteIndex] = {
          order: selectedImageOrder,
          makeGood: newListMakeGood,
          error: note
        }
      } else {
        newListImageNote.push({
          order: selectedImageOrder,
          makeGood: newListMakeGood,
          error: note
        })
      }
    }

    try {
      const param = {
        id: Number(id),
        order: selectedImageOrder,
        newImage: img,
        note: newListImageNote || [
          {
            order: selectedImageOrder,
            makeGood: newListMakeGood,
            error: note
          }
        ]
      }

      const axiosResponse = await axios.post(
        `${process.env.REACT_APP_API_REPORT}/api/scsc-check/save-image-error`,
        param,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${AccessToken}`
          }
        }
      )

      if (axiosResponse.data.statusCode === 200) {
        setCircles([])
        handleSetErrorImage(img, selectedImageOrder, newListMakeGood)
        notification.success({
          message: 'Thông báo!',
          description: <>Cập nhật thành công.</>
        })
      } else {
        notification.error({
          message: 'Thông báo!',
          description: <>Cập nhật thất bại.</>
        })
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Thông báo!',
        description: <>Cập nhật thất bại.</>
      })
      setLoading(false)
      throw error
    }
  }

  // thay ảnh đã chỉnh sửa vào list
  const handleSetErrorImage = (image: string, order: number, listMakeGood: any[]) => {
    const newListImage = [...listDefaultImage]
    try {
      if (isCutImage) {
        newListImage[order - 1] = image
        setListDefaultImage(newListImage)
      } else {
        setListDefaultImageCurl(image)
      }
      if (circles.length === 0 && !isResetImage) {
        if(imageError && (listMakeGood?.length || note)) {
          setIsNotEnoughCheckedCut(false)
        } 
        if(imageErrorCurling && (listMakeGood?.length || note)) { 
          setIsNotEnoughCheckedCurl(false)
        }
        setCheckImageError(true)
        setCheckedImageError(true)
      } else {
        if(isCutImage) {
          setCountImageErrorCut(countImageErrorCut + 1)
        } else {
           setCountImageErrorCurl(countImageErrorCurl + 1)
        }
        setCheckImageError(false)
        setCheckedImageError(false)
      }
      if (ListImageNote) {
        const newListImageNote = [...ListImageNote]
        if (newListImageNote.find((item: any) => item.order === order)) {
          newListImageNote[newListImageNote.findIndex((item: any) => item.order === selectedImageOrder)] = {
            order: selectedImageOrder,
            makeGood: null,
            error: note
          }
        } else {
          newListImageNote[ListImageNote.length] = {
            order: selectedImageOrder,
            makeGood: null,
            error: note
          }
        }
        setListDefaultNote(newListImageNote)
      } else {
        setListDefaultNote([
          {
            order: selectedImageOrder,
            makeGood: null,
            error: note
          }
        ])
      }
      setIsResetImage(false)
      setCheckDone(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  // Lấy kích thước stage điều chỉnh kích thước ảnh
  const getFittedDimensions = (imgWidth, imgHeight, containerWidth, containerHeight) => {
    const imgAspectRatio = imgWidth / imgHeight
    const containerAspectRatio = containerWidth / containerHeight

    let width, height

    if (imgAspectRatio > containerAspectRatio) {
      width = containerWidth
      height = containerWidth / imgAspectRatio
    } else {
      height = containerHeight
      width = containerHeight * imgAspectRatio
    }
    return { width, height }
  };



  const handleWheel = (e) => {
    e.evt.preventDefault();

    const stage = stageRef.current;
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * 0.9 : oldScale * 1.1;
    setScale(newScale);

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    setPosition(newPos);
  };

  const handleDragMove = (e) => {
    // console.log(`handleDragMove`);
    setPosition({ x: e.target.x(), y: e.target.y() });
  };

  const lastScaleRef = useRef(1);
  const lastCenterRef = useRef(null);

  const lastDistRef = useRef(0);

  const handleTouchStart = (e) => {
    if (e.evt.touches.length === 2) {
      // setIsPinching(true);
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      const dist = Math.sqrt(
        Math.pow(touch2.clientX - touch1.clientX, 2) +
        Math.pow(touch2.clientY - touch1.clientY, 2)
      );
      lastDistRef.current = dist;

      const center = {
        x: (touch1.clientX + touch2.clientX) / 2,
        y: (touch1.clientY + touch2.clientY) / 2,
      };
      lastCenterRef.current = center;
    }
  };

  const handleTouch = (e) => {
    e.evt.preventDefault();
    console.log(`handleTouch e.evt.touches`, e.evt.touches);
    if (e.evt.touches.length === 2) {
      setLockDrag(true);
    } else {
      setLockDrag(false);
    }

    if (e.evt.touches.length === 2) {
      // Xử lý pinch-to-zoom
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      // const dist = Math.sqrt(
      //   Math.pow(touch2.clientX - touch1.clientX, 2) +
      //   Math.pow(touch2.clientY - touch1.clientY, 2)
      // );

      // if (!lastCenterRef.current) {
      //   lastCenterRef.current = {
      //     x: (touch1.clientX + touch2.clientX) / 2,
      //     y: (touch1.clientY + touch2.clientY) / 2,
      //   };
      // }

      // if (!lastScaleRef.current) {
      //   lastScaleRef.current = dist;
      // }

      // const scaleFactor = dist / lastScaleRef.current;
      // const newScale = Math.max(0.5, Math.min(scale * scaleFactor, 3)); // Giới hạn scale từ 0.5 đến 3

      // setScale(newScale);


      const dist = Math.sqrt(
        Math.pow(touch2.clientX - touch1.clientX, 2) +
        Math.pow(touch2.clientY - touch1.clientY, 2)
      );

      const scaleFactor = dist / lastDistRef.current;
      const newScale = scale * scaleFactor;
      // newScale = Math.max(0.5, Math.min(newScale, 3)); // Giới hạn scale

      // Tính toán vị trí mới để giữ điểm trung tâm
      // const center = {
      //   x: (touch1.clientX + touch2.clientX) / 2,
      //   y: (touch1.clientY + touch2.clientY) / 2,
      // };
      const center = lastCenterRef.current;

      const mousePointTo = {
        x: (center.x - position.x) / scale,
        y: (center.y - position.y) / scale,
      };

      const newPos = {
        x: center.x - mousePointTo.x * newScale,
        y: center.y - mousePointTo.y * newScale,
      };

      setScale(newScale);
      // setPosition(clampPosition(newPos));
      setPosition(newPos);

      lastDistRef.current = dist;
      // lastCenterRef.current = center;

    }
  };

  function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  const handleTouchEnd = () => {
    lastCenterRef.current = null;
    lastScaleRef.current = null;
  };

  const handleDragGroup = (e) => {
    console.log(`handleDragGroup`, e.evt.touches);
    if (!e.evt.touches || e.evt.touches !== 2) {
      if (!lockDrag) {
        setPosition(clampPosition({ x: e.target.x(), y: e.target.y() }));
        // setPosition({ x: e.target.x(), y: e.target.y() });
      }
    }
  }

  const clampPosition = (pos) => {
    // console.log(`clampPosition`);

    const containerSize = 1000;

    const scaledWidth = fittedDimensions.width * scale;
    const scaledHeight = fittedDimensions.height * scale;

    const minX = Math.min(0, containerSize - scaledWidth);
    const maxX = Math.max(0, scaledWidth - containerSize);

    const minY = Math.min(0, containerSize - scaledHeight);
    const maxY = Math.max(0, scaledHeight - containerSize);

    return {
      x: Math.min(Math.max(pos.x, minX), maxX),
      y: Math.min(Math.max(pos.y, minY), maxY),
    };
  };

  const handleShapeDrag = (e, id) => {
    // console.log(`handleShapeDrag ${id}`);

    setCircles((circles) => circles.map((circle) => (circle.id === id ? { ...circle, x: e.target.x(), y: e.target.y() } : circle)));
  };

  const handleCircleChange = (value) => {
    // setSliderValue(value); // Cập nhật giá trị slider
  
    setCircles((circles) =>
      circles.map((circle) =>
        circle.id === activeCircleId
          ? { ...circle, radius: value }
          : circle
      )
    );
  };

  const handleSliderChange = (value) => {
    const stage = stageRef.current;
    const oldScale = stage.scaleX();

    const newScale = value / 100; // Scale từ slider
    const pointer = { x: stage.width() / 2, y: stage.height() / 2 }; // Giữ ảnh tại vị trí trung tâm

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    setScale(newScale);

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    setPosition(newPos);
  };

  Konva.hitOnDragEnabled = true;
  Konva.capturePointerEventsEnabled = true;

  return (
    <>
      <div style={{ width: '100%' }} ref={modalRef}></div>
      {selectedImage ? (
        <div className='cover-2'>
          <Spin spinning={!image || loading}>
              <div className='box'>
                {image && (
                      <Stage Stage
                      width={fittedDimensions.width}
                      height={fittedDimensions.height}
                      ref={stageRef}
                      // onTouchMove={handleTouchMove}
                      onWheel={handleWheel}
                      // onTouchStart={handleTouchStart}
                      // onTouchMove={handleTouch}
                      // onTouchEnd={handleTouchEnd}
                      // draggable
                      style={{alignSelf: 'center'}}
                      scaleX={scale}
                      scaleY={scale}
                      x={position.x}
                      y={position.y}
                    >
                      <Layer>
                        <Group
                          draggable
                          x={0}
                          y={0}
                          onDragMove={handleDragGroup}
                        // onTouchMove={handleTouch}
                        // onTouchEnd={handleTouchEnd}
                        // onDragMove={(e) => setPosition(clampPosition({ x: e.target.x(), y: e.target.y() }))}
                        >
                          <KonvaImage image={image}
                            width={fittedDimensions.width}
                            height={fittedDimensions.height}
                            x={0}
                            y={0}
                          // draggable
                          />
                          {circles.map((circle) => (
                            <Circle
                              key={circle.id}
                              x={circle.x}
                              y={circle.y}
                              // width={circle.witdh}
                              width={10}
                              // radius={isMobile ? 20 : 10}
                              radius={circle.radius}
                              stroke={'red'}
                              strokeWidth={2}
                              // draggable={activeCircleId === circle.id}
                              draggable
                              onDragMove={(e) => handleShapeDrag(e, circle.id)}
                              onMouseEnter={() => handleMouseEnter(circle.id)}
                              onMouseLeave={handleMouseLeave}
                              onTouchStart={(e) => handleCircleTouchStart(e, circle.id)}
                              onTouchEnd={handleCircleTouchEnd}
                            />
                          ))}
                        </Group>
                      </Layer>
                    </Stage>
                )}
              </div>
          </Spin>
          <div style={{position: 'absolute', right: isMobile ? -5 : 20, top: 50}}>
                <Slider
                      vertical
                      min={50}
                      max={800}
                      defaultValue={100}
                      onChange={handleSliderChange}
                      style={{ height: fittedDimensions.height}}
                    />
                    <PictureOutlined style={{fontSize: '20px', marginLeft: 5, }}/>
                </div>


          <div className='flex flex-col mt-2 '>
            {circles && isChange && (
               <Slider
               min={1}
               max={100}
               defaultValue={isMobile ? 20 : 30}
               onChange={handleCircleChange}
               step={1}
               trackStyle={{ backgroundColor: 'red' }} // Màu track
               handleStyle={{
                backgroundColor: 'red', // Màu nút kéo
                borderColor: 'blue', // Màu viền nút kéo
               }}
             />
            )}
           
            <div><br></br></div>
            <div className='flex flex-w button-cover'>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                icon={<PlusOutlined />}
                onClick={handleAddCircle}
              >
                Khoanh lỗi
              </Button>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={handleRemoveLastCircle}
                icon={<UndoOutlined rotate={90} />}
                disabled={circles.length === 0}
              >
                Quay lại
              </Button>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={handleRefreshImage}
                disabled={loading}
                icon={<RollbackOutlined />}
              >
                Ảnh gốc
              </Button>
            </div>
            <div className='flex items-center justify-center mt-2'>
              <span className='font-bold'>Chọn phương án xử lý (Có thể chọn nhiều):</span>
            </div>
            <Collapse defaultActiveKey={['0']} onChange={() => null} ghost bordered={false} expandIconPosition={'right'}>

              {listError && listError.map((item, index) => {
             
                // const listValueGroup = checkSelectedGroup[0]?.value;
                
                return (
                  <Panel header={item.group} key={item.id} style={{ maxWidth: '100%' }} >
                    {item.value.map((child) => {
                        const isSelected = listMakeGood.findIndex((i) => child.id === i.id)
                      return (
                        <div style={{ width: '300px' }} onClick={() => handleSelected(child)} key={child.id} className={`flex items-center item-select-container ${isSelected !== -1  ? 'selected' : ''}`}>
                          <span>{child.text} </span>
                          {isSelected !== -1 && <CheckOutlined style={{ fontSize: '14px', color: 'green' }} />}
                        </div>
                      )
                    })}
                  </Panel>
                )
              })}
            </Collapse>
            <div style={{ marginTop: '10px', width: '100%' }}>
              <TextArea
                value={note}
                onChange={(e) => handleChangeNote(e.target.value)}
                placeholder='Phương án xử lý khác'
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </div>
            <div className='flex items-center justify-center mt-2'>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60%' }}
                onClick={handleSaveImage}
                type={'primary'}
              >
                Lưu
              </Button>
            </div>
          </div>

        </div>
      ) : null}
    </>
  )
}

export default CheckError
