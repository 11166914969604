import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ColumnConfig, convertParamsAxiosBase } from '../../base/util'
import { SpinIndicator } from 'antd/es/spin'
import { Checkbox, Button, notification, InputNumber, Form, Row, Table, Spin, Select, TreeSelect } from 'antd'
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  getListServicesFlowSalon,
  getListSalonFlowService,
  getListServiceSalonV2,
  getListService,
  GetListServicesV2,
  createSalonServices,
  getDepartments
  // GetListServices
} from '../api'
import { getSalons } from 'api/catalog'

const { SHOW_ALL } = TreeSelect;

export const ServiceConfigProductSalon = () => {
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [form] = Form.useForm()
  const currentUrl = '/service/config-salon'
  const [listSalon, setListSalon] = useState([])
  const [listServices, setListServices] = useState([])
  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [dataChange, setDataChange] = useState([])
  interface RecordType {
    key: string;
    title: string;
  }
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [salonsTree, setSalonsTree] = useState<RecordType[]>([]);
  const [listDepartments, setListDepartments] = useState([]);
  const [salonTargets, setSalonTargets] = useState([])

	const onChangeTreeSelect = (newValue: string[]) => {
		console.log('onChange ', setSelectedValue);
		newValue = newValue.filter((item) => (!item.includes("@0@")));
		console.log('onChange newValue ',  newValue);
		const tmp = [];
		newValue.map((item) => {
			tmp.push( parseInt(item.split('@')[1], 10));
		});
		console.log('targetKeys',  tmp);
		setSelectedValue(newValue);
		setSalonTargets(tmp);
	}
  const getListDepartments = async () => {
      try {
        const response: any = await getDepartments()
        const { statusCode, data } = response
        //console.log("statusCode",statusCode)
        if (statusCode === 0) {
          //console.log("statusCode",statusCode)
          if (      
            data &&
            //data.data &&
            data.length > 0
          ) {
            setListDepartments(data)
            //
            const departmentSalon = data.filter((item) => (item.group === 1|| item.id===1));
            // console.log('departmentSalon',     
            //   departmentSalon);
            const salonsTree = createDepartmentTree(departmentSalon,0)
            setSalonsTree(salonsTree)
            console.log('salonsTree',     
              salonsTree);
          }
        }
      } catch (error) {
        console.error('Error:', error.message)
      }
    }
    function createDepartmentTree(departments, parentId) {
      return departments
          .filter((item) => item.parentId === parentId)
          .map((item) => ({
              ...item,
              title: item.name,
              // key: item.id+"@"+item.salonId+"@"+item.name, 
              value: item.id+"@"+item.salonId+"@"+item.name,
              // key: item.id, 
              // value:item.id,           
              children: createDepartmentTree(departments, item.id)
          }));
    }
  const handleListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleListServices = async () => {
    try {
      const response: any = await GetListServicesV2()
      if (response?.statusCode === 200) {
        setListServices(response?.data?.data.filter((c) => c.publish === true))
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    handleListSalon()
    handleListServices()
    getListDepartments()
  }, [])
  function updateOrAddProductById(products, updatedProduct) {
    // Sử dụng map để tạo một mảng mới với phần tử được cập nhật
    const updatedProducts = products.map((product) => {
      // Kiểm tra nếu productId và salonId của phần tử trùng với productId và salonId của sản phẩm cần cập nhật
      if (product.serviceId === updatedProduct.serviceId && product.salonId === updatedProduct.salonId) {
        // Thực hiện cập nhật
        return { ...product, ...updatedProduct }
      }
      // Nếu không phải sản phẩm cần cập nhật, giữ nguyên phần tử
      return product
    })

    // Kiểm tra xem sản phẩm có tồn tại trong mảng ban đầu không
    const isProductExist = updatedProducts.some(
      (product) => product.serviceId === updatedProduct.serviceId && product.salonId === updatedProduct.salonId
    )

    // Nếu sản phẩm không tồn tại, thêm nó vào cuối mảng
    if (!isProductExist) {
      updatedProducts.push(updatedProduct)
    }

    return updatedProducts
  }
  // console.log(dataChange, 'dataChange')
  const handleOnChange = (item: any, field: string, value: any) => {
    const newData = [...data]
    if (field !== 'isCheckAll') {
      const targetItem = newData.find(
        (dataItem) => dataItem.serviceId === item.serviceId && dataItem.salonId === item.salonId
      )
      if (targetItem) {
        switch (field) {
          case 'isCheck':
            targetItem.isCheck = !targetItem.isCheck
            setData(newData)
            break
          case 'price':
            targetItem.price = value
            setData(newData)
            break
        }
        const newDataArrayUpdate = updateOrAddProductById(dataChange, targetItem)
        setDataChange(newDataArrayUpdate)
      }
    } else {
      newData.map((dataItem) => (dataItem.isCheck = !isCheckAll))
      setDataChange(newData)
      setData(newData)
      setIsCheckAll(!isCheckAll)
    }
  }

  const column = [
    {
      title: () => {
        return <Checkbox onChange={() => handleOnChange(null, 'isCheckAll', null)}></Checkbox>
      },
      key: 'isCheck',
      render: (item, index, i) => {
        return <Checkbox checked={item.isCheck} onChange={() => handleOnChange(item, 'isCheck', null)}></Checkbox>
      }
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'ID dịch vụ',
      dataIndex: 'serviceId',
      key: 'serviceId'
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'serviceName',
      key: 'serviceName'
    },
    {
      title: 'Giá chính',
      dataIndex: 'originalPrice',
      key: 'originalPrice'
    },
    {
      title: 'Giá Salon',
      key: 'price',
      render: (record) => (
        <InputNumber
          value={record.price ? record.price : record.originalPrice}
          onChange={(value: number) => handleOnChange(record, 'price', value)}
        />
      )
    }
  ]

  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }

  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    handleChangeValue(changedValues, onReset)
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }
  const handleFetchListStaff = async (paramsSearch: any) => {
    console.log(paramsSearch, 'paramsSearch')
    try {
      if (!isChooseService) {
        // console.log('dang vao key 1')
        setTableLoading(true)
        const response = await getListServiceSalonV2(paramsSearch)
        if (response.data) {
          setData(response.data)
          if (response.data.length === response.data.filter((c) => c.isCheck === true).length) {
            setIsCheckAll(true)
          }
        }
      } else {
        // console.log('dang vao key 2')
        setTableLoading(true)
        const response = await getListService(paramsSearch.serviceId, { salonIds: paramsSearch.salonIds })
        if (response.data) {
          setData(response.data)
        }
        if (response.data.length === response.data.filter((c) => c.isCheck === true).length) {
          setIsCheckAll(true)
        }
      }
    } catch (error) {
      notification.error({
        description: 'Không thể thao tác, vui lòng thử lại sau',
        message: 'Lỗi'
      })
    }
    setLoading(false)
    setReload(false)
    setTableLoading(false)
  }
  const [params, setParams] = useState<any>({})

  const onFinish = () => {
    const value = form.getFieldsValue()
    console.log(value, 'value')
    const result: any = {}
    if (salonTargets?.length > 0) {
      const commaSeparatedString = salonTargets?.join(',')
      result.salonIds = commaSeparatedString
      result.serviceId = value.serviceId
    } else if (value.serviceId?.length > 0) {
      const commaSeparatedString = value.serviceId?.join(',')
      result.serviceIds = commaSeparatedString
      result.salonId = value.salonId
    } else {
      result.serviceId = value.serviceId
      result.salonId = value.salonId
    }
    navigate(
      `${currentUrl}?${convertParamsAxiosBase({
        ...result
      })}`
    )
  }

  const prefetchDataStaffList = () => {
    const paramsSearchResult = window.location.search.replace('?', '')
    if (Object.keys(paramsSearchResult).length > 0) {
      const params = new URLSearchParams(paramsSearchResult)
      const paramsObject = {}
      params.forEach(function (value, key) {
        paramsObject[key] = value
      })
      // console.log(paramsObject, 'paramsObject')
      handleFetchListStaff(paramsObject)
    } else {
      setData([])
    }
    if (!mount) setMount(true)
  }

  useEffect(() => {
    prefetchDataStaffList()
    // cập nhật các value cho thẻ selector
    if (searchParams.get('salonId')) {
      setSalonId(Number(searchParams.get('salonId')))
    }
    if (searchParams.get('salonIds')) {
      const arrayValue = searchParams.get('salonIds')?.split(',')?.map(Number)
      setSalonId(arrayValue)
      setIsChooseService(true)
    }
    if (searchParams.get('serviceId')) {
      setServiceId(Number(searchParams.get('serviceId')))
    }
    if (searchParams.get('serviceIds')) {
      const arrayValue = searchParams.get('serviceIds')?.split(',')?.map(Number)
      setServiceId(arrayValue)
      setIsChooseSalon(true)
    }
  }, [window.location.search])
  // logic khi chọn salon - service
  const [salonId, setSalonId] = useState<any>(null)
  const [serviceId, setServiceId] = useState<any>(null)
  const [loadingSelectSalon, setLoadingSelectSalon] = useState<boolean>(false)
  const [loadingSelectService, setLoadingSelectService] = useState<boolean>(false)
  const [isChooseSalon, setIsChooseSalon] = useState<boolean>(false)
  const [isChooseService, setIsChooseService] = useState<boolean>(false)
  const handleOnchangeServices = async (e: any) => {
    if (!isChooseSalon && e) {
      form.setFieldValue('salonId', undefined)
      try {
        // console.log(body, 'body')
        setIsChooseService(true)
        setLoadingSelectSalon(true)
        onChangeTreeSelect([])
        const response = await getListSalonFlowService({}, e)
        // console.log(response, 'response')
        if (response.data) {
          const listSalon = response.data?.map((data) => {
            return {
              id: data.salonId,
              name: data.salonName
            }
          })
          console.log(listSalon, 'listSalon')
          setListSalon(listSalon)
        }
        setLoadingSelectSalon(false)
      } catch (error) {
        setLoadingSelectSalon(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('serviceId', e)
    setParams({ ...params, ['serviceId']: e })
    if (!e) {
      handleListSalon()
    }
  }
  const handleOnchangeSalon = async (e: any) => {
    if (!isChooseService && e) {
      form.setFieldValue('serviceId', undefined)
      try {
        // console.log(body, 'body')
        setIsChooseSalon(true)
        setLoadingSelectService(true)
        const response = await getListServicesFlowSalon({}, e)
        // console.log(response, 'response')
        if (response.data) {
          const listServices = response.data?.map((data) => {
            return {
              serviceId: data.serviceId,
              name: data.serviceName
            }
          })
          // console.log(listServices, 'listServices')
          setListServices(listServices)
        }
        setLoadingSelectService(false)
      } catch (error) {
        setLoadingSelectService(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('salonId', e)
    setParams({ ...params, ['salonId']: e })
    if (!e) {
      handleListServices()
    }
  }
  const handleUpdateConfig = async () => {
    try {
      if (dataChange.length > 0) {
        // const newData = dataChange.filter((c) => c.price !== null && c.price !== undefined)
        const bodyFilter = dataChange.map((data) => {
          return {
            salonId: data.salonId,
            serviceId: data.serviceId,
            price: data.price ? data.price : data.originalPrice,
            isCheck: data.isCheck
          }
        })
        const response: any = await createSalonServices(bodyFilter)
        console.log(response)
        if (response.statusCode === 200) {
          notification.success({
            description: response.message,
            message: 'Thành công'
          })
        } else {
          notification.error({
            description: response.message,
            message: 'Lỗi'
          })
        }
      } else {
        return
      }
    } catch (error) {
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }
  const handleResetFilter = () => {
    form.resetFields()
    setSalonId(null)
    setServiceId(null)
    setLoadingSelectSalon(false)
    setLoadingSelectService(false)
    setIsChooseSalon(false)
    setIsChooseService(false)
    navigate(currentUrl)
    handleListSalon()
    handleListServices()
    onChangeTreeSelect([])
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <h2 className='text-2xl font-bold mb-7'>Cấu hình dịch vụ Salon</h2>
          <Row className=''>
            <Form.Item
              name='salonId'
              label='Chọn Salon'
              rules={[{ required: false, message: 'Bạn phải chọn Salon' }]}
              className='w-[400px]'
              // key={salonId}
            >
              {isChooseService ? (
                // <Select
                //   allowClear
                //   className='w-full'
                //   defaultValue={salonId ? salonId : undefined}
                //   placeholder={'Chọn Salon'}
                //   fieldNames={{ label: 'name', value: 'id' }}
                //   options={listSalon}
                //   showSearch
                //   optionFilterProp='children'
                //   onChange={(e) => handleOnchangeSalon(e)}
                //   filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                //   loading={loadingSelectSalon}
                //   // disabled={loadingSelectSalon}
                //   mode='multiple'
                // />
                <TreeSelect treeData={salonsTree} value={selectedValue} onChange={onChangeTreeSelect} 
                treeCheckable="true" showCheckedStrategy={SHOW_ALL} placeholder='Chọn Salon' />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={salonId ? salonId : undefined}
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectSalon}
                  // disabled={loadingSelectSalon}
                />
              )}
            </Form.Item>
            <Form.Item
              name='serviceId'
              label='Chọn Dịch vụ'
              rules={[{ required: false, message: 'Bạn phải chọn Dịch vụ' }]}
              className='w-[400px] ml-10'
              key={JSON.stringify(serviceId)}
            >
              {isChooseSalon ? (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn dịch vụ'}
                  fieldNames={{ label: 'name', value: 'serviceId' }}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectService}
                  disabled={loadingSelectService}
                  mode='multiple'
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  fieldNames={{ label: 'name', value: 'serviceId' }}
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn dịch vụ'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectService}
                  disabled={loadingSelectService}
                />
              )}
            </Form.Item>
            <Button
              type='default'
              htmlType='submit'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            <Button
              onClick={handleResetFilter}
              type='default'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button>
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                scroll={{ x: 1500, y: 550 }}
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                pagination={false}
                bordered
                loading={tableLoading}
              />
            </Row>
          </div>
        </Form>
        <Button type='primary' onClick={handleUpdateConfig}>
          Cập nhật cấu hình
        </Button>
      </div>
    </div>
  )
}
