/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from "react"

interface Props {
    defaultSCSCForm: any,
    getCheckedPoint: (url) => void,
    onChangeForm: any,
    isErrorImage: boolean,
    form: any,
    isChecked: any
}
const FormCheckCurl = (props: Props) => {
    const { defaultSCSCForm, getCheckedPoint, onChangeForm, isErrorImage, form, isChecked } = props
    const [listChecked, setListSelected] = useState<any>({
        HairTipsSelected: 0,
        HairRootSelected: 0,
        HairWavesSelected: 0,
    })

    const onClickCheck = (id: number, key: number) => {
        if (isErrorImage || isChecked) return
        switch (key) {
            case 1:
                setListSelected((item) => ({
                    ...item,
                    HairTipsSelected: item.HairTipsSelected === id ? 0 : id,
                }));
                onChangeForm("hairTipId", form.hairTipId === id ? 0 : id)
                break;
            case 2:
                setListSelected((item) => ({
                    ...item,
                    HairRootSelected: item.HairRootSelected === id ? 0 : id,
                }));
                onChangeForm("hairRootId", form.hairRootId === id ? 0 : id)
                break;
            case 3:
                setListSelected((item) => ({
                    ...item,
                    HairWavesSelected: item.HairWavesSelected === id ? 0 : id,
                }));
                onChangeForm("hairWaveId", form.hairWaveId === id ? 0 : id)
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (listChecked) {
            getCheckedPoint(listChecked)
        }
    }, [listChecked]);

    useEffect(() => {
        if (isErrorImage) {
            setListSelected({
                HairTipsSelected: 0,
                HairRootSelected: 0,
                HairWavesSelected: 0,
            })
        }
    }, [isErrorImage]);

    useEffect(() => {
        if (form) {
            setListSelected({
                HairTipsSelected: form.hairTipId,
                HairRootSelected: form.hairRootId,
                HairWavesSelected: form.hairWaveId,
            })
        }
    }, [form]);
    return (
        <div className=''>
            {defaultSCSCForm ?
                <div>
                    <div className='flex flex-row justify-between mt-5'>
                        <div className='w-full'>
                            <div>
                            Ngọn tóc
                            </div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#FF0000] ml-2">0đ</span> - Không cân đối bề mặt 2 bên mai, 2 bên góc trán, bề mặt sau tai gáy lồi lõm.</div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#42AC4A] ml-2">1đ</span> - Cân đối bề mặt 2 bên mai, 2 bên góc trán, sau tai gáy. Mặt cắt chuốt mịn.</div>
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                        {defaultSCSCForm.listHairTips.map((item: any) => (
                                <div key={item.idScscCate} className='flex-1 max-w-[150px]' onClick={() => onClickCheck(item.idScscCate, 1)}>
                                    <img
                                        src={listChecked.HairTipsSelected === item.idScscCate ?
                                            item.scscCateImageActive
                                            :
                                            item.scscCateImage}
                                        alt=''
                                        className='cursor-pointer '
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mt-5'>
                        <div className='w-full'>
                            <div>
                            Chân tóc
                            </div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#FF0000] ml-2">0đ</span> - Không cân đối bề mặt 2 bên mai, 2 bên góc trán, bề mặt sau tai gáy lồi lõm.</div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#42AC4A] ml-2">1đ</span> - Cân đối bề mặt 2 bên mai, 2 bên góc trán, sau tai gáy. Mặt cắt chuốt mịn.</div>
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                        {defaultSCSCForm.listHairRoot.map((item: any) => (
                                <div key={item.idScscCate} className='flex-1 max-w-[150px]' onClick={() => onClickCheck(item.idScscCate, 2)}>
                                    <img
                                        src={listChecked.HairRootSelected === item.idScscCate ?
                                            item.scscCateImageActive
                                            :
                                            item.scscCateImage}
                                        alt=''
                                        className='cursor-pointer '
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mt-5'>
                        <div className='w-full'>
                            <div>
                            Sóng tóc
                            </div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#FF0000] ml-2">0đ</span> - Không cân đối bề mặt 2 bên mai, 2 bên góc trán, bề mặt sau tai gáy lồi lõm.</div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#42AC4A] ml-2">1đ</span> - Cân đối bề mặt 2 bên mai, 2 bên góc trán, sau tai gáy. Mặt cắt chuốt mịn.</div>
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                        {defaultSCSCForm.listHairWaves.map((item: any) => (
                                <div key={item.idScscCate} className='flex-1 max-w-[150px]' onClick={() => onClickCheck(item.idScscCate, 3)}>
                                    <img
                                        src={listChecked.HairWavesSelected === item.idScscCate ?
                                            item.scscCateImageActive
                                            :
                                            item.scscCateImage}
                                        alt=''
                                        className='cursor-pointer '
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                :
                null
            }
        </div>
    )
}

export default FormCheckCurl
