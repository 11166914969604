import { Button } from 'antd'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { useState, useEffect } from 'react'
import { getListInventory, getListRequestOrder } from './api'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { ParamsSearchType } from '../staff/interface'
export const ListRerquestOrder = () => {
  const navigate = useNavigate()
  const [listInventory, setListInventory] = useState([])
  const column: ColumnConfig[] = [
    {
      title: 'ID đơn',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Id kho',
      dataIndex: 'inventoryId',
      key: 'inventoryId'
    },
    {
      title: 'Tên kho NCC',
      dataIndex: 'inventoryName',
      key: 'inventoryName'
    },
    {
      title: 'Ngày ra đơn order',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render(_, record, index) {
        return <p>{record.createdDate && dayjs(record.createdDate).format('DD/MM/YYYY')}</p>
      }
    },
    {
      title: 'Ngày duyệt đơn order',
      dataIndex: 'dateConfirm',
      key: 'dateConfirm',
      render(_, record, index) {
        return <p>{record.dateConfirm && dayjs(record.dateConfirm).format('DD/MM/YYYY')}</p>
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isConfirm',
      key: 'isConfirm',
      render: (_, record) => {
        return record.isConfirm === null ? (
          <p>Chưa duyệt</p>
        ) : record.isConfirm === true ? (
          <p>Đã duyệt</p>
        ) : (
          <p>Không duyệt</p>
        )
      }
    },
    {
      title: '',
      dataIndex: 'viewDetail',
      key: 'viewDetail',
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              navigate(`/requestOrder/detail/${record.id}`)
            }}
          >
            Xem chi tiết
          </Button>
        )
      }
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Kho NCC',
      param: 'InventoryId',
      type: TypeSearch.select,
      options: listInventory,
      placeholder: 'Lựa chọn',
      fieldNames: { label: 'name', value: 'id' },
      col: 6
    },
    {
      label: 'Ngày ra đơn order',
      param: 'Created',
      type: TypeSearch.date,
      col: 6
    },
    {
      label: 'Trạng thái đơn',
      param: 'IsConfirm',
      type: TypeSearch.select,
      options: [
        { label: 'Tất cả trạnng thái', value: null },
        { label: 'Đã duyệt', value: true },
        { label: 'Chưa duyệt', value: false }
      ],
      placeholder: 'Lựa chọn',
      col: 4
    }
  ]
  const [reload, setReload] = useState(false)

  const getListDataRequestOrder = async () => {
    const params = handleGetQueryParams()
    const res: any = await getListRequestOrder(params)
    return res
  }

  const handleGetListInventory = async () => {
    const response: any = await getListInventory(4)
    setListInventory(response)
  }
  useEffect(() => {
    handleGetListInventory()
    handleGetQueryParams()
  }, [])
  useEffect(() => {
    console.log(reload)
  }, [reload])
  const handleGetQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}

    for (const [key, value] of searchParams.entries()) {
      console.log(key, value)
      params[key] = value
    }
    return params
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/requestOrder/list'
        title='Duyệt đơn order từ NCC'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListDataRequestOrder}
        exportCsv={false}
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
