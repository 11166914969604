/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import './index.scss'
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  TreeSelect,
  DatePicker,
  TimePicker,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  message,
  Upload,
  Switch,
  InputNumber,
  Checkbox,
  notification,
  Table
} from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  updateProduct,
  getListProductsBrand,
  processingList,
  getDetailProduct,
  getListProductGroup,
  listCategoryProducts,
  getListProductsSelector,
  listProducts,
  createProduct,
  getListUnit,
  GenerateCodeProduct,
  getListBranchProducts,
  getListProductInventory,
  getListProducMaterialType,
  getListGroupProduct,
  getListCategoryProducts,
  getListInventoryProduct
} from './api'
import { removeVietnamese } from '../../utils/selector/removeVietnamese'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { convertToNumberArray, convertToStringArray } from '../../utils/selector/Array'
import UploadCustom from 'utils/upload/index'

const { TextArea } = Input
export const DetailProductsPage: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [initialValues, setInitialValues] = useState<any>()
  // phần thẻ selector
  const [error, setError] = useState<any>({
    listInventoryType: null,
    // IvMaterialType: null,
    CheckPro: null,
    unit: null,
    CategoryId: null,
    TopupMoney: null,
    // GroupProductId: null,
    Name: null,
    Price: null,
    CoefficientOfWaitingDays: null
  })
  const [listProductsInCombo, setListProductsInCombo] = useState<any[]>([])
  const [InventoryType, setInventoryType] = useState<any>()
  const [listInventoryType, setListInventoryType] = useState([])
  const [IvMaterialType, setIvMaterialType] = useState<any>()
  const [listIvMaterialType, setListIvMaterialType] = useState([])
  const [unit, setUnit] = useState<any>()
  const [listUnit, setListUnit] = useState([])
  const [GroupProductId, setGroupProductId] = useState(null)
  const [listGroupProductId, setListGroupProductId] = useState([])
  const [CategoryId, setCategoryId] = useState<string>()
  const [CategoryEdit, setCategoryEdit] = useState<any>()
  const [listCategoryId, setListCategoryId] = useState([])
  const [listBrandId, setListBrandId] = useState([])
  const [BrandId, setBrandId] = useState<any>()
  // mapping products
  const [listMapingServices, setListMapingServices] = useState<any[]>([])
  const [selectedServices, setSelectedServices] = useState<any>()
  const [dataSelectServices, setDataListServices] = useState<any[]>([])
  // phần giá trị boolean

  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  // các trường bolean
  const [typeCheck, setTypeCheck] = useState<number>(0)
  const [CheckCombo, setCheckCombo] = useState<boolean>(false)
  // const [Status, setStatus] = useState<boolean>(false)
  const [Publish, setPublish] = useState<boolean>(false)
  // ghi nhận tiền thu trước
  const [isPrepaid, setIsPrepaid] = useState<boolean>(false)
  // Sản phẩm tồn kho (Hóa chất)
  const [CheckInventoryHC, setCheckInventoryHC] = useState<boolean>(false)
  // Theo dõi mức dùng vật tư
  const [IsCheckVatTu, setIsCheckVatTu] = useState<boolean>(false)
  // Sản phẩm ngừng kinh doanh
  const [StopBusiness, setStopBusiness] = useState<boolean>(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [productId, setProductId] = useState()
  const [listInventory, setListInventory] = useState([])
  const [inventoryId, setInventoryId] = useState<number>(0)
  const [isChange, setIsChange] = useState(false)

  const [isTopupMoneyProduct, setIsTopupMoneyProduct] = useState<boolean>(false)
  const [topupValue, setTopupValue] = useState<any>()

  const handleGetListProductsType = async () => {
    try {
      const response: any = await getListProductInventory()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListInventoryType(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const getListInventorys = async () => {
    try {
      const response: any = await getListInventoryProduct()
      setListInventory(
        response.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      )
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleGetListIvMaterialType = async () => {
    try {
      const response: any = await getListProducMaterialType()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListIvMaterialType(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleGetListUnit = async () => {
    try {
      const response: any = await getListUnit()
      const { statusCode, data } = response
      console.log(data, 'data')
      if (statusCode === 200) {
        setListUnit(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleGetListProductsGroup = async () => {
    try {
      const response: any = await getListGroupProduct()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListGroupProductId(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const genCateChildren = (itemChild: any[], prefix: string) => {
    return itemChild.map((item) => {
      if (item.children && item.children.length > 0) {
        return {
          title: item.title,
          key: prefix + item.value,
          value: genCateChildren(item.children, 'child')
        }
      } else
        return {
          title: item.title,
          value: prefix + item.value
        }
    })
  }

  const handleGetListCategory = async () => {
    try {
      const response: any = await getListCategoryProducts()
      if (response.statusCode === 200) {
        const dataCate = response.data.map((item) => {
          if (item.children && item.children.length > 0) {
            return {
              title: item.title,
              value: 'parent-' + item.value,
              children: genCateChildren(item.children, 'child-')
            }
          } else
            return {
              title: item.title,
              value: 'parent-' + item.value
            }
        })

        console.log(`end handleGetListCategory`, dataCate)

        setListCategoryId(dataCate)

        return true
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const searchCategory = (child, prefix, oriValue) => {
    const key = prefix + oriValue
    console.log(`searchCategory key=${key}`, child, child.length)

    if (!child) return null

    for (let i = 0; i < child.length; i++) {
      console.log(`searchCategory key=${key} element=${child[i].value}`, child[i].children)

      if (key === child[i].value) return child[i].value

      if (child[i].children && child[i].children.length > 0) {
        const data = searchCategory(child[i].children, 'child-', oriValue)
        if (data) return data
      }
    }

    return null
  }

  const getListBrands = async () => {
    try {
      const response: any = await getListBranchProducts()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListBrandId(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const getListProducts = async () => {
    try {
      const response: any = await listProducts({ page: 0, pageSize: 0 })
      const { statusCode, data } = response
      if (statusCode === 200) {
        const dataselect = data?.data.map((item) => {
          return {
            label: item.name,
            value: item.productId
          }
        })
        setDataListServices(dataselect)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const handleGetDetailProduct = async (id) => {
    try {
      const response: any = await getDetailProduct(id)
      if (response.data) {
        const initial = {
          ProductId: response.data.productId,
          Code: response.data.code,
          Name: response.data.name,
          BarCode: response.data.barCode,
          OnWebId: response.data.onWebId,
          ModelName: response.data.modelName,
          Volume: response.data.volume,
          Description: response.data.description,
          Cost: response.data.cost,
          Price: response.data.price,
          VoucherPercent: response.data.voucherPercent,
          ForSalary: response.data.forSalary,
          Order: response.data.order,
          CoefficientOfWaitingDays: response.data.coefficientOfWaitingDays,
          DefaultMaxNorm: response.data.defaultMaxNorm,
          CampID: response.data.campID,
          ExpDate: response.data.expDate
        }

        console.log(`initial`, initial)

        // phần thẻ selector
        setInventoryType(response.data.inventoryType)
        setIvMaterialType(response.data.ivMaterialType)
        setUnit(response.data.unit)
        setGroupProductId(response.data.groupProductId)

        setCategoryEdit(response.data.categoryId)
        setTopupValue(response.data.topupValue)
        if (response?.data?.topupValue > 0) {
          setIsTopupMoneyProduct(true)
        }
        setBrandId(response.data.brandId)
        setListMapingServices(response.data.mapIdProduct)
        // phần boolean
        setCheckCombo(response.data.checkCombo)
        setTypeCheck(response.data.typeCheck)
        // setStatus(response.data.status)
        setPublish(response.data.publish)
        setIsPrepaid(response.data.isPrepaid)
        setCheckInventoryHC(response.data.checkInventoryHC)
        setIsCheckVatTu(response.data.isCheckVatTu)
        setStopBusiness(response.data.stopBusiness)
        setInventoryId(response.data.inventoryId)
        setImageUrl(response.data.image)
        setInitialValues(initial)
        setSelectedServices(convertToNumberArray(response.data?.mapIdProduct))
        if (dataSelectServices.length > 0) {
          setListProductsInCombo(
            response.data?.productCombo.map((item) => {
              const checkName = dataSelectServices.find((c) => c.value === item.id)
              if (checkName) {
                return {
                  id: item.id,
                  name: checkName.label,
                  number: item.number
                }
              }
            })
          )
        } else {
          setListProductsInCombo(response.data?.productCombo)
        }
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  useEffect(() => {
    handleGetListProductsType()
    handleGetListUnit()
    handleGetListProductsGroup()
    getListInventorys()
    handleGetListCategory()
    getListBrands()
    getListProducts()
    handleGetListIvMaterialType()
  }, [])
  useEffect(() => {
    if (listProductsInCombo) {
      const newListProductCombo = listProductsInCombo.map((item) => {
        const checkName = dataSelectServices.find((c) => c.value === item.id)
        if (checkName) {
          return {
            id: item.id,
            name: checkName.label,
            number: item.number
          }
        }
      })
      setListProductsInCombo(newListProductCombo)
    }
  }, [dataSelectServices])
  useEffect(() => {
    async function setCate() {
      if (!listCategoryId) return
      const dataCate = [...listCategoryId]
      const categoryDetailId = searchCategory(dataCate, 'parent-', CategoryEdit)
      console.log('categoryDetail', categoryDetailId)
      setCategoryId(categoryDetailId)
    }

    setCate()
  }, [CategoryEdit, listCategoryId])

  useEffect(() => {
    if (id) {
      handleGetDetailProduct(id)
    }
  }, [id])
  // console.log(selectedServices, 'selectedServices')
  const onFinish = async (values: any) => {
    console.log('Success:', values)
    try {
      setError({
        typeOfSupplies: null,
        listInventoryType: null,
        // IvMaterialType: null,
        CheckPro: null,
        unit: null,
        CategoryId: null,
        TopupMoney: null,
        // GroupProductId: null,
        Name: null,
        Price: null,
        CoefficientOfWaitingDays: null
      })
      const currentError = {
        typeOfSupplies: null,
        listInventoryType: null,
        // IvMaterialType: null,
        CheckPro: null,
        unit: null,
        CategoryId: null,
        TopupMoney: null,
        // GroupProductId: null,
        Name: null,
        Price: null,
        CoefficientOfWaitingDays: null
      }
      if (!InventoryType) {
        currentError.listInventoryType = 'Vui lòng chọn loại sản phẩm'
      }
      if (InventoryType === 2 && !IvMaterialType) {
        currentError.typeOfSupplies = 'Vui lòng chọn loại vật tư'
      }
      // if (!IvMaterialType) {
      //   currentError.IvMaterialType = 'Vui lòng chọn loại sản phẩm'
      // }
      if (typeCheck !== 1 && typeCheck !== 2 && typeCheck !== 0) {
        currentError.CheckPro = 'Vui lòng chọn nhịp kiểm hàng ngày'
      }
      if (!unit) {
        currentError.unit = 'Vui lòng nhập đơn vị sản phẩm'
      }
      if (!CategoryId) {
        currentError.CategoryId = 'Vui lòng nhập danh mục sản phẩm'
      }
      if (isTopupMoneyProduct && topupValue <= 0) {
        currentError.TopupMoney = 'Vui lòng nhập giá trị Topup tiền'
      }
      // if (!GroupProductId) {
      //   currentError.GroupProductId = 'Vui lòng nhập nhóm sản phẩm'
      // }
      if (!values.Name) {
        currentError.Name = 'Vui lòng nhập tên sản phẩm'
      }
      if (values.Price.length === 0 || values.Price < 0) {
        currentError.Price = 'Vui lòng nhập giá bán sản phẩm'
      }
      if (values.CoefficientOfWaitingDays.length === 0 || values.CoefficientOfWaitingDays < 0) {
        currentError.CoefficientOfWaitingDays = 'Vui lòng nhập hệ số ngày chờ hàng'
      }

      setError(currentError)

      if (
        !InventoryType ||
        (InventoryType === 2 && !IvMaterialType) ||
        (typeCheck !== 1 && typeCheck !== 2 && typeCheck !== 0) ||
        !unit ||
        !CategoryId || (isTopupMoneyProduct && topupValue <= 0) ||
        !values.Name ||
        values.Price.length === 0 ||
        values.Price < 0 ||
        values.CoefficientOfWaitingDays.length === 0 ||
        values.CoefficientOfWaitingDays < 0
      ) {
        console.log('validate runing')
        return
      }
      console.log('inventoryId', inventoryId)
      const newCategoryId = Number(CategoryId.replace('parent-', '').replace('child-', ''))
      const body = {
        name: values.Name,
        code: values.Code,
        barCode: values.BarCode,
        onWebId: values.OnWebId,
        modelName: values.ModelName,
        volume: values.Volume,
        description: values.Description,
        cost: values.Cost,
        order: values.Order,
        price: values.Price,
        voucherPercent: values.VoucherPercent,
        forSalary: values.ForSalary,
        coefficientOfWaitingDays: values.CoefficientOfWaitingDays,
        defaultMaxNorm: values.DefaultMaxNorm,
        campID: values.CampID,
        expDate: values.ExpDate,
        // Images: dataListImageORG, k cập nhật hình ảnh
        inventoryType: InventoryType.toString(),
        ivMaterialType: IvMaterialType,
        unit: unit,
        groupProductId: GroupProductId,
        categoryId: newCategoryId,
        topupValue: newCategoryId === 156 ? topupValue : undefined,
        publish: Publish,
        brandId: BrandId,
        checkCombo: CheckCombo,
        typeCheck: typeCheck,
        inventoryId: inventoryId,
        // status: Status,
        isPrepaid: isPrepaid ? 1 : 0,
        checkInventoryHC: CheckInventoryHC,
        isCheckVatTu: IsCheckVatTu,
        stopBusiness: StopBusiness ? 1 : 0,
        productCombo: listProductsInCombo,
        mapIdProduct: convertToStringArray(selectedServices),
        image: imageUrl
      }
      console.log(body, 'body')
      const response: any = await updateProduct(id, body)
      if (response) {
        if (response.statusCode === 200) {
          notification.success({
            description: 'Cập nhật dữ liệu của Sản phẩm thành công',
            message: 'Thành công'
          })
          handleGetDetailProduct(id)
          setIsChange(false)
        } else {
          setIsChange(false)
          notification.error({
            description: response.message,
            message: 'Thất bại'
          })
        }
        // navigate('/service/list?PageSize=10&PageIndex=1')
      } else {
        setIsChange(false)
        notification.warning({
          description: 'Không thể thay đổi dữ liệu của sản phẩm',
          message: 'Lỗi'
        })
      }
    } catch (error) {
      setIsChange(false)
      console.error('Error:', error)
      notification.error({
        description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu',
        message: 'Lỗi'
      })
    }
  }

  const onValuesChange = async (changedValues: any) => {
    console.log('oke')
  }

  const onChangeInventoryType = async (changedValues: any) => {
    setInventoryType(changedValues)
    setIsChange(true)
  }

  const onChangeCategory = (newValue: string) => {
    console.log('onChangeCategory', newValue)

    setCategoryId(newValue)
  }

  useEffect(() => {
    if (InventoryType && isChange) {
      setIvMaterialType(null)
    }
  }, [InventoryType, isChange])

  useEffect(() => {
    if (CategoryId) {
      const newCategoryId = Number(CategoryId.replace('parent-', '').replace('child-', ''))
      if (newCategoryId === 156) {
        setIsTopupMoneyProduct(true)
      } else {
        setIsTopupMoneyProduct(false)
      }
    }
  }, [CategoryId])

  return (
    <div style={{ minHeight: 'calc(100vh)', margin: 'auto' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        key={JSON.stringify(initialValues, error)}
        className='xl:ml-[1.5%] 2xl:ml-[1.5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete='off'
        initialValues={initialValues}
      >
        <h3 className='font-bold text-[22px] mt-3 ml-10'>CHỈNH SỬA THÔNG TIN SẢN PHẨM</h3>
        <div className='mt-12 custom-form-details-form'>
          <div>
            <Form.Item label='ID sản phẩm ERP:' name='ProductId' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <Input className='w-[160px]' disabled />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span> Loại sản phẩm
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative', top: -8 }}>
                <div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                    }
                    value={InventoryType ? Number(InventoryType) : undefined}
                    style={{ width: '220px' }}
                    onChange={(value: any) => onChangeInventoryType(value)}
                    placeholder='Chọn loại sản phẩm'
                    options={listInventoryType}
                  />
                  {error.listInventoryType && (
                    <div className='mt-1 text-xs text-red-600'>{error.listInventoryType}</div>
                  )}
                </div>
                <div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                    }
                    value={IvMaterialType ? Number(IvMaterialType) : undefined}
                    style={{ width: '220px', margin: '0 20px' }}
                    onChange={(value: any) => setIvMaterialType(value)}
                    placeholder='Chọn loại vật tư'
                    options={listIvMaterialType}
                  />
                  {/* <div className='mt-1 ml-5 text-xs text-red-600'>{error.IvMaterialType}</div> */}
                  {error.typeOfSupplies && <div className='mt-1 ml-5 text-xs text-red-600'>{error.typeOfSupplies}</div>}
                </div>
                <Form.Item label='' name='Code' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} className='mt-2'>
                  <Input style={{ width: 120 }} disabled />
                </Form.Item>

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                  <span style={{ fontWeight: '500' }}>Combo</span>
                  <Checkbox
                    checked={CheckCombo}
                    style={{ marginLeft: 10, marginTop: 2 }}
                    onChange={() => setCheckCombo(!CheckCombo)}
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span> Nhịp kiểm hàng
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 0 }}>
                  <span style={{ fontWeight: '500' }}>Kiểm hàng ngày</span>
                  <Checkbox
                    checked={typeCheck === 1 ? true : false}
                    style={{ marginLeft: 10, marginTop: 2 }}
                    onChange={() => setTypeCheck(1)}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 90 }}>
                  <span style={{ fontWeight: '500' }}>Kiểm khi salon cần</span>
                  <Checkbox
                    checked={typeCheck === 2 ? true : false}
                    style={{ marginLeft: 10, marginTop: 2 }}
                    onChange={() => setTypeCheck(2)}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 90 }}>
                  <span style={{ fontWeight: '500' }}>Không cần kiểm</span>
                  <Checkbox
                    checked={typeCheck === 0 ? true : false}
                    style={{ marginLeft: 10, marginTop: 2 }}
                    onChange={() => setTypeCheck(0)}
                  />
                </div>
              </div>
              {error.CheckPro && <div className='mt-1 ml-5 text-xs text-red-600'>{error.CheckPro}</div>}
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Đơn vị
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                  }
                  value={unit ? Number(unit) : undefined}
                  style={{ width: '220px' }}
                  onChange={(value: any) => setUnit(value.toString())}
                  placeholder='Chọn Đơn vị'
                  options={listUnit}
                />
              </div>
              {error.unit && <div className='mt-1 text-xs text-red-600'>{error.unit}</div>}
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Tên danh mục/Tên nhóm sản phẩm
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ width: 'calc(50% - 10px)' }}>
                  <TreeSelect
                    showSearch
                    filterTreeNode={(input, option: any) =>
                      removeVietnamese(option?.title ?? '').includes(removeVietnamese(input)) ||
                      removeVietnamese(option?.value ?? '').includes(removeVietnamese(input))
                    }
                    allowClear
                    className='w-full'
                    value={CategoryId}
                    placeholder='Chọn danh mục'
                    treeData={listCategoryId}
                    onChange={onChangeCategory}
                    treeNodeFilterProp='title'
                  />
                  {error.CategoryId && <div className='mt-1 text-xs text-red-600'>{error.CategoryId}</div>}
                </div>
                <div style={{ width: 'calc(50% - 10px)' }}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
                    }
                    value={GroupProductId ? Number(GroupProductId) : undefined}
                    className='w-full'
                    onChange={(value) => setGroupProductId(value)}
                    placeholder='Chọn tên nhóm sản phẩm'
                    options={listGroupProductId}
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                  {/* {error.GroupProductId && <div className='mt-1 text-xs text-red-600'>{error.GroupProductId}</div>} */}
                </div>
              </div>
            </Form.Item>

            {/* Nhập tiền topup */}
            {isTopupMoneyProduct &&
              <Form.Item
                label={
                  <>
                    <span className='mr-2 text-red-600'>(*)</span>Tiền Topup
                  </>
                }
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
              >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ width: 'calc(50% - 10px)' }}>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => setTopupValue(e.target.value.replace(" ",""))}
                      value={topupValue}
                      placeholder='Nhập tiền Topup' />
                    {error.TopupMoney && <div className='mt-1 text-xs text-red-600'>{error.TopupMoney}</div>}
                  </div>
                </div>
              </Form.Item>
            }

            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Tên sản phẩm /Barcode
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Form.Item label='' name='Name' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Nhập tên sản phẩm' />
                  {error.Name && <div className='mt-1 text-xs text-red-600'>{error.Name}</div>}
                </Form.Item>
                <Form.Item label='' name='BarCode' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Nhập barcode' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label='Thương hiệu/Map 30shine Store:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                  }
                  value={BrandId ? Number(BrandId) : undefined}
                  style={{ width: 'calc(50% - 10px', marginTop: -7 }}
                  onChange={(value) => setBrandId(value)}
                  placeholder='Chọn thương hiệu'
                  options={listBrandId}
                />
                <Form.Item label='' name='OnWebId' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='30shinestore Id' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label='Nhà cung cấp:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                  }
                  value={inventoryId ? inventoryId : undefined}
                  style={{ width: 'calc(50% - 10px', marginTop: -7 }}
                  onChange={(value) => setInventoryId(value)}
                  placeholder='Chọn nhà cung cáp'
                  options={listInventory}
                  mode='multiple'
                />
              </div>
            </Form.Item>
            <Form.Item label='Tên model/Thể tích:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Form.Item label='' name='ModelName' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Tên Model' />
                </Form.Item>
                <Form.Item label='' name='Volume' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Thể tích' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label='Mô tả:' name='Description' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <TextArea maxLength={600} placeholder='Nhập mô tả' style={{ height: 120 }} />
            </Form.Item>
            {CheckCombo && (
              <Form.Item label='Sản phẩm:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                <div>
                  <Select
                    mode='multiple'
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                    }
                    className='w-full'
                    onChange={(value) => setSelectedServices(value)}
                    placeholder='Lựa chọn Sản phẩm'
                    options={dataSelectServices}
                    value={selectedServices ? selectedServices : undefined}
                  />
                  {/* <Button
                    type='default'
                    className='ml-10'
                    disabled={selectedServices ? false : true}
                    onClick={handleAddListMappingServices}
                  >
                    Thêm sản phẩm
                  </Button> */}
                </div>
              </Form.Item>
            )}
            {listProductsInCombo?.length > 0 && (
              <Form.Item label='Nhập số lượng sp trong combo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                <div>
                  {listProductsInCombo.map((item: any, index: number) => {
                    return (
                      <div key={index} className='flex py-2 row'>
                        <div className='w-[70%]'>{item.name}:</div>
                        <Input
                          style={{ width: '30%', marginLeft: '10px' }}
                          defaultValue={item.number}
                          type='number'
                          placeholder='Ví dụ: 1'
                          onChange={(e) => {
                            const newListProductCombo = listProductsInCombo
                            const check = newListProductCombo.find((c) => c.id === item.id)
                            if (check) {
                              check.number = e.target.value
                              setListProductsInCombo(newListProductCombo)
                            }
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </Form.Item>
            )}
            <Form.Item label='Giá nhập:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item label='' name='Cost' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Ví dụ: 1000000' />
                </Form.Item>
                <span style={{ fontWeight: '500', marginLeft: 20 }}>VNĐ</span>
              </div>
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Giá bán
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item label='' name='Price' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Ví dụ: 1000000' />
                </Form.Item>
                <span style={{ fontWeight: '500', marginLeft: 20 }}>VNĐ</span>
              </div>
              {error.Price && <div className='text-xs text-red-600'>{error.Price}</div>}
            </Form.Item>
            {/* <Form.Item label='Khuyến mại giảm giá:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item label='' name='VoucherPercent' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Ví dụ: 5' />
                </Form.Item>
                <span style={{ fontWeight: '500', marginLeft: 20 }}> ( % )</span>
              </div>
            </Form.Item> */}
            <Form.Item label='Chiết khấu lương:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item label='' name='ForSalary' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Ví dụ: 5' />
                </Form.Item>
                <span style={{ fontWeight: '500', marginLeft: 20 }}> ( % )</span>
              </div>
            </Form.Item>
            {/* <Form.Item label='Order:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <Form.Item label='' name='Order' style={{ width: 'calc(50% - 10px)' }}>
                <Input style={{ width: '100%' }} placeholder='Ví dụ: 1' />
              </Form.Item>
            </Form.Item> */}
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Hệ số ngày chờ hàng
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item label='' name='CoefficientOfWaitingDays' style={{ width: 'calc(50% - 10px)' }}>
                <Input style={{ width: '100%' }} placeholder='Ví dụ: 1' />
              </Form.Item>
              {error.CoefficientOfWaitingDays && (
                <div className='mt-1 text-xs text-red-600'>{error.CoefficientOfWaitingDays}</div>
              )}
            </Form.Item>
            {/* <Form.Item label='Mặc định tồn max:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <Form.Item label='' name='DefaultMaxNorm' style={{ width: 'calc(50% - 10px)' }}>
                <Input style={{ width: '100%' }} placeholder='Ví dụ: 1' />
              </Form.Item>
            </Form.Item> */}
            <Form.Item label='CampaignID:' name='CampID' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <Input style={{ width: 'calc(50% - 10px' }} placeholder='CampaignID' />
            </Form.Item>
            <Form.Item label='Số ngày sử dụng:' name='ExpDate' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <InputNumber min={1} max={50000} placeholder={'3'} />
            </Form.Item>
            {/* <Form.Item label='Nổi bật:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={Status} onChange={() => setStatus(!Status)} />
              </div>
            </Form.Item> */}
            <Form.Item label='Publish:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={Publish} onChange={() => setPublish(!Publish)} />
              </div>
            </Form.Item>
            <Form.Item label='Ghi nhận tiền thu trước:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={isPrepaid} onChange={() => setIsPrepaid(!isPrepaid)} />
              </div>
            </Form.Item>
            <Form.Item label='Sản phẩm tồn kho (Hóa chất):' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={CheckInventoryHC} onChange={() => setCheckInventoryHC(!CheckInventoryHC)} />
              </div>
            </Form.Item>
            <Form.Item label='Theo dõi mức dùng vật tư:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={IsCheckVatTu} onChange={() => setIsCheckVatTu(!IsCheckVatTu)} />
              </div>
            </Form.Item>
            <Form.Item label='Sản phẩm ngừng kinh doanh:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={StopBusiness} onChange={() => setStopBusiness(!StopBusiness)} />
              </div>
            </Form.Item>

            <Form.Item label='Hình ảnh:' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} className='mt-8'>
              <UploadCustom
                setImageUrl={setImageUrl}
                fileList={fileList}
                setFileList={setFileList}
                classCustomAdd=''
                classCustomDetail=''
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item label='' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Button
            className=' mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
