import { author } from './config'

interface paramsProps {
  page: number
  pageSize: number
}
export const getListInventory = (type) => {
  return author.get(`/api/inventory/get-by-type?type=${type}`)
}
export const getListProduct = (id) => {
  return author.get(`/api/request-order/get-product?inventoryId=${id}`)
}
export const getDataDetail = (id) => {
  return author.get(`/api/request-order/get-detail?id=${id}`)
}
export const confirmRequest = (params) => {
  return author.post(`/api/request-order/confirm-request`, params)
}
export const saveProductInfo = (params) => {
  return author.put(`/api/request-order/save-product-info`, params)
}
export const getInventoryProduct = (params) => {
  return author.get(`/api/request-order/get-inventory?id=${params}`)
}
export const checkChangeInventoryProduct = (params) => {
  return author.get(
    `/api/request-order/check-change-inventory-product?productId=${params.productId}&fromInventory=${params.fromInventory}&toInventory=${params.toInventory}`
  )
}
export const changeInventoryProduct = (params, id) => {
  return author.put(`/api/request-order/change-inventory-product/${id}`, params)
}
export const getListRequestOrder = (params) => {
  console.log(params)
  let url = `/api/request-order?page=${params?.PageIndex}&pageSize=${params?.PageSize}`
  if (params?.InventoryId) {
    url += `&inventoryId=${params.InventoryId}`
  }
  if (params?.Created_to && params?.Created_to !== 'Invalid Date') {
    url += `&toDate=${params.Created_to}`
  }
  if (params?.Created_from && params?.Created_from !== 'Invalid Date') {
    url += `&fromDate=${params.Created_from}`
  }
  if (params?.IsConfirm && params?.IsConfirm !== 'Invalid Date') {
    console.log('IsConfirm')
    url += `&isConfirm=${params.IsConfirm}`
  }
  return author.get(url)
}
