/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Table } from "antd"
import dayjs from "dayjs"
import { Link, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'


const TableListBill = (props: any) => {
    const { ListData, params } = props
    const navigate = useNavigate()
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (index, record) => (
                <div>
                    {((params.PageIndex - 1) * params.PageSize) + index + 1}
                </div>
            ),
        },
        {
            title: 'Bill Id',
            dataIndex: 'billId',
            key: 'billId',
        },
        {
            title: 'Ngày chia',
            dataIndex: 'date',
            key: 'date',
            render: (value, record) => (
                <div>
                    {value}
                </div>
            ),
        },
        {
            title: 'Người chấm',
            dataIndex: 'markBy',
            key: 'markBy',
        },
        {
            title: 'Tình trạng chấm',
            dataIndex: 'isCheck',
            key: 'isCheck',
            render: (value, record) => (
                <>
                    {value ?
                        <div>Đã chấm</div>
                        :
                        <div>Chưa chấm</div>
                    }
                </>
            ),
        },
        {
            title: 'Hành động',
            dataIndex: '',
            key: '',
            render: (item) => (
                <>
                    {item?.isCheck ?
                        <div></div>
                        :
                        <Button
                            // className='cursor-pointer w-fit text-[#1890FF]'
                            type="primary"
                            onClick={() => {
                                navigate(`/quality/check-scsc/${item.id}`)
                            }}
                        >
                            Chấm
                        </Button>
                    }
                </>
            ),
        }

    ]
    const columnsMB = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            width: 30,
            render: (index, record) => (
                <div>
                    {((params.PageIndex - 1) * params.PageSize) + index + 1}
                </div>
            ),
        },
        {
            title: 'Bill Id',
            dataIndex: 'billId',
            key: 'billId',
            width: 50,
        },
        {
            title: 'Tình trạng chấm',
            dataIndex: 'isCheck',
            key: 'isCheck',
            width: 100,
            render: (value, record) => (
                <>
                    {value ?
                        <div>Đã chấm</div>
                        :
                        <div>Chưa chấm</div>
                    }
                </>
            ),
        },
        {
            title: 'Hành động',
            dataIndex: '',
            key: '',
            render: (item) => (
                <>
                    {item?.isCheck ?
                        <div></div>
                        :
                        <Button
                            // className='cursor-pointer w-fit text-[#1890FF]'
                            type="primary"
                            onClick={() => {
                                navigate(`/quality/check-scsc/${item.id}`)
                            }}
                        >
                            Chấm
                        </Button>
                    }
                </>
            ),
        }

    ]
    return (
        <div >
            <Table className={''} columns={columnsMB} dataSource={ListData} pagination={false} bordered />
        </div>
    )
}
export default TableListBill
