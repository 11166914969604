/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { SearchOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, Pagination, Row, Select, Spin, Tabs } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { getListSCSC, getListStaff, convertParamsAxios, getListAdminSCSC } from '../../api/api'
import { setListStaff, setListSalonDefault, setSearchParamsSCSCAdmin } from '../../slides/SCSCSlice'
import convert from 'utils/helpers/convert'
import { useNavigate } from 'react-router-dom'
import TableListBill from './components/tableListBill'
import './index.scss'
import TableListBySalon from './components/tableListBySalon'
import { check } from 'prettier'
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker'
import { convertQueryStringToObject } from 'pages/staff/api'
import { getSalons } from 'api/catalog'

const ListAdminCheckSCSC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const { listStaffSCSC, listSalonDefault, searchParamsSCSCAdmin } = useAppSelector((state) => state.SCSCSlice)
  const [listStaffCustom, setListStaffCustom] = useState([])
  const [listIteam, setListItem] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [listSalon, setListSalon] = useState([])
  const [tab, setTab] = useState(1)
  const [chosenSalonId, setChosenSalonId] = useState<number>(0)
  const [chosenDate, setChosenDate] = useState<string>('')
  console.log(tab, 'tab')
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const [params, setParams] = useState<any>({
    PageSize: 10,
    PageIndex: 1
  })

  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]
    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params,
        [keyName]: value || undefined
      })
    }
  }
  const handleFetchSCSC = async (dataSearch) => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await getListSCSC({
        userName: dataSearch.userName || undefined,
        date: dataSearch?.Date,
        isCheck: dataSearch?.Status === undefined ? undefined : dataSearch?.Status,
        pageSize: dataSearch.PageSize,
        page: dataSearch.PageIndex,
        billId: dataSearch?.BillId,
        salonId: chosenSalonId || undefined
      })
      const data = response?.data || null
      if (data) {
        const value = data
        setListItem(
          value.map((item, index) => {
            return {
              ...item,
              index: index
            }
          })
        )
        setTotalItems(response?.totalData)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const getListStaffSCSC = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response = await getListStaff()
      if (response) {
        dispatch(setListStaff(response))
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const getListSalon = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        dispatch(setListSalonDefault(data))
      }
      setLoading(false)
    } catch (error) {
      console.error('Error:', error.message)
      setLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    setTab(Number(key))
    setChosenSalonId(0)
    setChosenDate('')
  }

  const onClickShowMore = (salonId, date) => {
    setChosenSalonId(salonId)
    setChosenDate(date)
  }

  useEffect(() => {
    if (!listStaffSCSC) {
      getListStaffSCSC()
    } else {
      setListStaffCustom(listStaffSCSC)
    }
  }, [listStaffSCSC])

  useEffect(() => {
    if (!listSalonDefault) {
      getListSalon()
    } else {
      setListSalon(
        listSalonDefault.map((item) => ({
          ...item,
          value: item.id,
          label: item.name
        }))
      )
    }
  }, [listSalonDefault])

  const onFinish = () => {
    const newUrlParams = convertParamsAxios({
      ...searchParamsSCSCAdmin,
      userName: searchParamsSCSCAdmin.userName,
      Date: searchParamsSCSCAdmin?.Date,
      BillId: searchParamsSCSCAdmin?.BillId,
      PageSize: searchParamsSCSCAdmin.PageSize,
      PageIndex: searchParamsSCSCAdmin.PageIndex,
      Status: searchParamsSCSCAdmin?.Status === undefined ? undefined : searchParamsSCSCAdmin?.Status
    })
    console.log(newUrlParams, 'newUrlParams')
    const currentUrlParams = window.location.search.substring(1)
    console.log(newUrlParams === currentUrlParams, 'newUrlParams === currentUrlParams')
    if (newUrlParams === currentUrlParams) {
      // getDataByParamsSearch()
      return
    } else {
      navigate(
        `/quality/check-scsc/list/admin?${convertParamsAxios({
          ...searchParamsSCSCAdmin,
          userName: searchParamsSCSCAdmin.userName,
          Date: searchParamsSCSCAdmin?.Date,
          BillId: searchParamsSCSCAdmin?.BillId,
          PageSize: searchParamsSCSCAdmin.PageSize,
          PageIndex: searchParamsSCSCAdmin.PageIndex,
          Status: searchParamsSCSCAdmin?.Status === undefined ? undefined : searchParamsSCSCAdmin?.Status
        })}`
      )
    }
  }

  const onFinish2 = async () => {
    if (loading) return
    setLoading(true)
    const result = {
      PageSize: 0,
      PageIndex: 0
    }
    const values = form.getFieldsValue()
    result.PageSize = params.PageSize
    result.PageIndex = params.PageIndex

    try {
      const response: any = await getListAdminSCSC({
        date: values?.date ? dayjs(values.date).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY'),
        pageSize: result.PageSize,
        page: result.PageIndex,
        salonId: chosenSalonId ? chosenSalonId : values.salonId
      })
      const data = response?.data.data || null
      if (data) {
        const value = data
        setListItem(
          value.map((item, index) => {
            return {
              ...item,
              index: index
            }
          })
        )
        setTotalItems(response?.data?.totalRecord)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const getDataByParamsSearch = () => {
    const paramsSearchResult: any = convertQueryStringToObject(window.location.search.replace('?', ''))
    if (Object.keys(paramsSearchResult).length > 0) {
      const dataSearch = {
        BillId: paramsSearchResult?.BillId ? paramsSearchResult?.BillId[0] : undefined,
        Date: paramsSearchResult?.Date ? paramsSearchResult?.Date[0] : undefined,
        userName: paramsSearchResult?.userName ? paramsSearchResult?.userName[0] : undefined,
        Status: paramsSearchResult?.Status ? paramsSearchResult?.Status[0].includes('true') : undefined,
        PageSize: paramsSearchResult?.PageSize ? Number(paramsSearchResult?.PageSize) : 10,
        PageIndex: paramsSearchResult?.PageIndex ? Number(paramsSearchResult?.PageIndex) : 1
      }
      dispatch(
        setSearchParamsSCSCAdmin({
          ...searchParamsSCSCAdmin,
          ...dataSearch
        })
      )
      handleFetchSCSC(dataSearch)
    } else {
      setListItem([])
    }
  }

  const onChangeDate = (value: DatePickerProps['value']) => {
    console.log(value, 'valuevalue')
    dispatch(
      setSearchParamsSCSCAdmin({
        ...searchParamsSCSCAdmin,
        Date: value ? dayjs(value).format('DD/MM/YYYY') : undefined
      })
    )
  }

  useEffect(() => {
    if (window.location.search) {
      getDataByParamsSearch()
      console.log('ssssssssss')
    } else {
      const dataSearch = {
        BillId: undefined,
        Date: dayjs().format('DD/MM/YYYY'),
        userName: undefined,
        Status: undefined,
        PageSize: 10,
        PageIndex: 1
      }
      dispatch(
        setSearchParamsSCSCAdmin({
          ...searchParamsSCSCAdmin,
          ...dataSearch
        })
      )
      handleFetchSCSC(dataSearch)
    }
  }, [window.location.search])

  console.log(searchParamsSCSCAdmin, 'searchParamsSCSCAdmin')
  //giới hạn ngày
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // const minDate = dayjs().subtract(7, 'day').startOf('day')
    const maxDate = dayjs().endOf('day')
    // return current && (current < minDate || current > maxDate)
    return current && current > maxDate
  }
  return (
    <div>
      <Spin tip='Đang xử lý dữ liệu...' spinning={loading}>
        <div className='mt-3 bg-white ' style={{ padding: isMobile ? '20px 0px 50px 0px' : '20px 20px 100px 20px' }}>
          <div className='text-2xl font-semibold max-sm:pl-2'>Check SCSC</div>
          <Tabs defaultActiveKey='1' onChange={onChangeTab} className='px-2 pb-2 m-auto mt-4 bg-white rounded '>
            <Tabs.TabPane tab='Chọn theo người chấm' key='1'>
              <div className={isMobile ? 'w-full search-wrapper' : 'w-full search-wrapper'}>
                <Form className='pb-10' form={form} onFinish={onFinish}>
                  <Row>
                    <div className={isMobile ? 'flex flex-wrap px-4 justify-between	' : 'flex flex-row gap-x-3'}>
                      <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                        <Input
                          value={searchParamsSCSCAdmin.BillId}
                          placeholder='Tìm bill Id'
                          onChange={(e) => {
                            dispatch(
                              setSearchParamsSCSCAdmin({
                                ...searchParamsSCSCAdmin,
                                BillId: e.target.value
                              })
                            )
                          }}
                        />
                      </Form.Item>
                      <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                        <Select
                          placeholder='Chọn trạng thái'
                          allowClear
                          value={searchParamsSCSCAdmin?.Status}
                          onChange={(e) => {
                            dispatch(
                              setSearchParamsSCSCAdmin({
                                ...searchParamsSCSCAdmin,
                                Status: e === undefined ? undefined : e
                              })
                            )
                          }}
                          options={[
                            {
                              value: true,
                              label: 'Đã chấm'
                            },
                            {
                              value: false,
                              label: 'Chưa chấm'
                            }
                          ]}
                        />
                      </Form.Item>
                      <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                        <Select
                          showSearch
                          allowClear
                          value={searchParamsSCSCAdmin?.userName || undefined}
                          placeholder='Chọn người chấm'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            convert
                              .removeVietnameseAccent(option?.label ?? '')
                              .indexOf(convert.removeVietnameseAccent(input)) >= 0
                          }
                          options={listStaffCustom.map((item, index) => {
                            return {
                              ...item,
                              key: index,
                              value: item.userName,
                              label: `(${item.id}) ${item.userName}`
                            }
                          })}
                          onChange={(value) =>
                            dispatch(
                              setSearchParamsSCSCAdmin({
                                ...searchParamsSCSCAdmin,
                                userName: value
                              })
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                        <DatePicker
                          value={
                            searchParamsSCSCAdmin?.Date ? dayjs(searchParamsSCSCAdmin.Date, 'DD/MM/YYYY') : undefined
                          }
                          disabledDate={disabledDate}
                          style={{ width: '100%' }}
                          placeholder='Chọn ngày'
                          format={'DD/MM/YYYY'}
                          onChange={onChangeDate}
                        />
                      </Form.Item>
                      <Button
                        type='default'
                        htmlType='submit'
                        className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
                        disabled={loading}
                      >
                        <div className='flex items-center justify-center hover_icon'>
                          <SearchOutlined className='mr-1 text-white' />
                          Xem dữ liệu
                        </div>
                      </Button>
                    </div>
                  </Row>
                  <TableListBill ListData={listIteam} params={searchParamsSCSCAdmin} />
                  <div className='grid mt-8 justify-items-end'>
                    <Pagination
                      className=''
                      current={searchParamsSCSCAdmin.PageIndex}
                      pageSize={searchParamsSCSCAdmin.PageSize}
                      total={totalItems}
                      onChange={(page: number, pageSize: number) => {
                        navigate(
                          `/quality/check-scsc/list/admin?${convertParamsAxios({
                            ...searchParamsSCSCAdmin,
                            PageSize: pageSize,
                            PageIndex: searchParamsSCSCAdmin.PageSize !== pageSize ? 1 : page
                          })}`
                        )
                        setTimeout(
                          () =>
                            window.document.getElementById('body__page').scrollTo({
                              top: 0,
                              behavior: 'smooth'
                            }),
                          500
                        )
                      }}
                    />
                  </div>
                </Form>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Chọn theo salon' key='2'>
              <div className={isMobile ? 'w-full search-wrapper' : 'w-full search-wrapper'}>
                <Form className='pb-10' form={form} onFinish={onFinish2} onValuesChange={onValuesChange}>
                  <Row>
                    <div className={isMobile ? 'flex flex-wrap px-4 justify-between	' : 'flex flex-row gap-x-3'}>
                      <Form.Item label='' name='date' style={{ width: isMobile ? '45%' : '100%' }}>
                        <DatePicker
                          defaultValue={dayjs()}
                          disabledDate={disabledDate}
                          style={{ width: '100%' }}
                          format={'DD/MM/YY'}
                          placeholder='Chọn ngày'
                        />
                      </Form.Item>
                      {listSalon && (
                        <Form.Item label='' name='salonId' style={{ width: isMobile ? '45%' : '100%' }}>
                          <Select
                            showSearch
                            allowClear
                            placeholder='Chọn Salon'
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              convert
                                .removeVietnameseAccent(option?.label ?? '')
                                .indexOf(convert.removeVietnameseAccent(input)) >= 0
                            }
                            options={listSalon}
                          />
                        </Form.Item>
                      )}
                      <Button
                        type='default'
                        htmlType='submit'
                        className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
                        onClick={() => setChosenSalonId(0)}
                      >
                        <div className='flex items-center justify-center hover_icon'>
                          <SearchOutlined className='mr-1 text-white' />
                          Xem dữ liệu
                        </div>
                      </Button>
                    </div>
                  </Row>
                  {chosenSalonId ? (
                    <TableListBill ListData={listIteam} params={params} />
                  ) : (
                    <TableListBySalon
                      ListData={listIteam}
                      params={params}
                      setIsShowMore={(salonId, date) => onClickShowMore(salonId, date)}
                    />
                  )}
                  <div className='grid mt-8 justify-items-end'>
                    <Pagination
                      className=''
                      current={params.PageIndex}
                      pageSize={params.PageSize}
                      total={totalItems}
                      onChange={(page: number, pageSize: number) => {
                        setParams({ ...params, PageSize: pageSize, PageIndex: params.PageSize !== pageSize ? 1 : page })
                        navigate(
                          `/quality/check-scsc/list/admin?${convertParamsAxios({
                            ...params,
                            PageSize: pageSize,
                            PageIndex: params.PageSize !== pageSize ? 1 : page
                          })}`
                        )
                        setTimeout(
                          () =>
                            window.document.getElementById('body__page').scrollTo({
                              top: 0,
                              behavior: 'smooth'
                            }),
                          500
                        )
                      }}
                    />
                  </div>
                </Form>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  )
}

export default ListAdminCheckSCSC
