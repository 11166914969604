/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from "antd";
import { useState, useEffect } from "react"

interface Props {
    defaultSCSCForm: any,
    getCheckedPoint: (url) => void,
    onChangeForm: any,
    isErrorImage: boolean,
    form: any,
    isChecked: any,
}
const FormCheckCut = (props: Props) => {
    const { defaultSCSCForm, getCheckedPoint, onChangeForm, isErrorImage, form, isChecked } = props
    const [listChecked, setListSelected] = useState<any>({
        ShapeSelected: 0,
        ConnectiveSelected: 0,
        SharpnessSelected: 0,
        CompletionSelected: 0,
        RptCompletionSelected: 0,
    })

    const onClickCheck = (id: number, key: number) => {
        if (isErrorImage || isChecked) return
        switch (key) {
            case 1:
                setListSelected((item) => ({
                    ...item,
                    ShapeSelected: item.ShapeSelected === id ? 0 : id,
                }));
                onChangeForm("shapeId", form.shapeId === id ? 0 : id)
                break;
            case 2:
                setListSelected((item) => ({
                    ...item,
                    ConnectiveSelected: item.ConnectiveSelected === id ? 0 : id,
                }));
                onChangeForm("connectiveId", form.connectiveId === id ? 0 : id)
                break;
            case 3:
                setListSelected((item) => ({
                    ...item,
                    SharpnessSelected: item.SharpnessSelected === id ? 0 : id,
                }));
                onChangeForm("sharpnessId", form.sharpnessId === id ? 0 : id)
                break;
            case 4:
                setListSelected((item) => ({
                    ...item,
                    CompletionSelected: item.CompletionSelected === id ? 0 : id,
                }));
                onChangeForm("completionId", form.completionId === id ? 0 : id)
                break;
            case 5:
                setListSelected((item) => ({
                    ...item,
                    RptCompletionSelected: item.RptCompletionSelected === id ? 0 : id,
                }));
                onChangeForm("creativeId", form?.creativeId === id ? 0 : id)
                break;
            default:
                break;
        }
    };
    
    useEffect(() => {
        if (listChecked) {
            getCheckedPoint(listChecked)
        }
    }, [listChecked]);

    useEffect(() => {
        if (isErrorImage) {
            setListSelected({
                ShapeSelected: 0,
                ConnectiveSelected: 0,
                SharpnessSelected: 0,
                CompletionSelected: 0,
                RptCompletionSelected: 0,
            })
        }
    }, [isErrorImage]);

    useEffect(() => {
        if (form) {
            setListSelected({
                ShapeSelected: form.shapeId,
                ConnectiveSelected: form.connectiveId,
                SharpnessSelected: form.sharpnessId,
                CompletionSelected: form.completionId,
                RptCompletionSelected: form.creativeId || 0,
            })
        }
    }, [form]);

    return (
        <div className=''>
            {defaultSCSCForm ?
                <div>
                     <div className='flex flex-row justify-between mt-5'>
                        <div className='w-full'>
                            <div>
                            1.SHAPE (HÌNH KHỐI)
                            </div>
                            <div style={{fontSize: '12px' }}>-<span className="text-[#FF0000] ml-2">0đ</span> - Không cân đối bề mặt 2 bên mai, 2 bên góc trán, bề mặt sau tai gáy lồi lõm.</div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#42AC4A] ml-2">1đ</span> - Cân đối bề mặt 2 bên mai, 2 bên góc trán, sau tai gáy. Mặt cắt chuốt mịn.</div>
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                        {defaultSCSCForm.listShape.map((item: any) => (
                                    <div key={item.idScscCate} className='flex-1 max-w-[150px]' onClick={() => onClickCheck(item.idScscCate, 1)}>
                                        <img
                                            src={listChecked.ShapeSelected === item.idScscCate ?
                                                item.scscCateImageActive
                                                :
                                                item.scscCateImage}
                                            alt=''
                                            className='cursor-pointer '
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mt-5'>
                        <div className='w-full'>
                            <div>
                            2.CONNECTIVE (LIÊN KẾT)
                            </div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#FF0000] ml-2">0đ</span> - Không có độ bám đuổi đều. Bị gờ, lẹm và phân tầng rõ rệt.</div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#42AC4A] ml-2">1đ</span> - Bề mặt phần Top có liên kết bám đuổi đều, mịn, ôm phom.</div>
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                            {defaultSCSCForm.listConnective.map((item: any) => (
                                <div key={item.idScscCate} className='flex-1 max-w-[150px]' onClick={() => onClickCheck(item.idScscCate, 2)}>
                                    <img
                                        src={listChecked.ConnectiveSelected === item.idScscCate ?
                                            item.scscCateImageActive
                                            :
                                            item.scscCateImage}
                                        alt=''
                                        className='cursor-pointer '
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mt-5'>
                        <div className='w-full'>
                            <div>
                            3.SHARPNESS (SẮC NÉT)
                            </div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#FF0000] ml-2">0đ</span> - Đường nét lởm chởm, bị vấp. Cạo viền chưa tròn, đều.</div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#42AC4A] ml-2">1đ</span> - Đường viền vành tai cạo nét và tròn. Độ chuyển màu đẹp mắt.</div>
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                            {defaultSCSCForm.listSharpness.map((item: any) => (
                                <div key={item.idScscCate} className='flex-1 max-w-[150px]' onClick={() => onClickCheck(item.idScscCate, 3)}>
                                    <img
                                        src={listChecked.SharpnessSelected === item.idScscCate ?
                                            item.scscCateImageActive
                                            :
                                            item.scscCateImage}
                                        alt=''
                                        className='cursor-pointer '
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mt-5'>
                    <div className='w-full'>
                            <div>
                            4.COMPLETION (HOÀN THIỆN)
                            </div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#FF0000] ml-2">0đ</span> - Sấy tạo kiểu chưa phồng, vuốt sáp bết, chưa có độ bám đuổi, ôm phom.</div>
                            <div style={{fontSize: '12px'}}> -<span className="text-[#42AC4A] ml-2">1đ</span> - Sấy tạo kiểu có độ phồng đều, ôm phom, vuốt sáp không bị bết.</div>
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                            {defaultSCSCForm.listCompletion.map((item: any) => (
                                <div key={item.idScscCate} className='flex-1 max-w-[150px]' onClick={() => onClickCheck(item.idScscCate, 4)}>
                                    <img
                                        src={listChecked.CompletionSelected === item.idScscCate ?
                                            item.scscCateImageActive
                                            :
                                            item.scscCateImage}
                                        alt=''
                                        className='cursor-pointer '
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className='flex flex-row justify-between mt-5'>
                        <div className='w-[200px]'>5.CREATIVE (SÁNG TẠO)</div>
                        <div className='grid grid-cols-2 gap-5'>
                            {defaultSCSCForm.rptCompletionList.map((item: any) => (
                                <div key={item.idScscCate} className='flex-1 max-w-[150px]' onClick={() => onClickCheck(item.idScscCate, 5)}>
                                    <img
                                        src={listChecked.RptCompletionSelected === item.idScscCate ?
                                            item.scscCateImageActive
                                            :
                                            item.scscCateImage}
                                        alt=''
                                        className='cursor-pointer '
                                    />
                                </div>
                            ))}
                        </div>
                    </div> */}
                </div>
                :
                null
            }
        </div>
    )
}

export default FormCheckCut
