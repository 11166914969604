/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Input, Spin, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getDefaultSCSCForm, getDetailSCSC, postSCSCCheck } from 'pages/quality/api/api'
import { setDefaultSCSCForm } from 'pages/quality/slides/SCSCSlice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { useMediaQuery } from 'react-responsive'
import FormCheckCut from './components/formCheckCut'
import FormCheckImageCut from './components/formCheckImageCut'
import TextArea from 'antd/es/input/TextArea'
import FormCheckCurl from './components/formCheckCurl'
import dayjs from 'dayjs'
import { setSearchParamsSCSC, setSearchParamsSCSCAdmin } from '../../slides/SCSCSlice';

const defaultForm = {
  id: 0,
  imageError: 0,
  totalPoint: 0,
  shapeId: 0,
  sharpnessId: 0,
  completionId: 0,
  pointError: 0,
  maxPointSharpId: 0,
  maxPointConnectiveId: 0,
  maxPointSharpNessId: 0,
  maxPointCompletionId: 0,
  workDate: "",
  curlingPointError: 0,
  hairTipId: 0,
  hairRootId: 0,
  hairWaveId: 0,
  imageErrorCurling: 0,
  staffId: 0,
  connectiveId: 0,
  creativeId: 0,
  maxPointCreative: 0,
  billId: 0
}
const CheckSCSC = () => {
  const navigate = useNavigate()
  const { userInfo } = useAppSelector((state) => state.user)
  const urlAccess = useAppSelector((state) => state.user.urlAccess)
  const { id } = useParams();
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>()
  const { defaultSCSCForm, searchParamsSCSCAdmin, searchParamsSCSC } = useAppSelector((state) => state.SCSCSlice)
  const [pointCut, setPointCut] = useState<number>(0)
  const [pointCretive, setPointCretive] = useState<number>(0)
  const [pointCurl, setPointCurl] = useState<number>(0)
  const listIdCutChecked = [8, 11, 15, 19]
  const listIdCretive = [31, 29]
  const listIdCurlChecked = [24, 26, 28]
  const [listDefaultNote, setListDefaultNote] = useState<any>()
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const [form, setForm] = useState(
    {
      id: 0,
      imageError: 0,
      totalPoint: 0,
      shapeId: 0,
      sharpnessId: 0,
      completionId: 0,
      pointError: 0,
      maxPointSharpId: 0,
      maxPointConnectiveId: 0,
      maxPointSharpNessId: 0,
      maxPointCompletionId: 0,
      workDate: "",
      curlingPointError: 0,
      hairTipId: 0,
      hairRootId: 0,
      hairWaveId: 0,
      imageErrorCurling: 0,
      staffId: 0,
      connectiveId: 0,
      creativeId: 0,
      maxPointCreative: 0,
      billId: 0
    }
  )
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [checkImageCutError, setCheckImageCutError] = useState<boolean>(true)
  const [checkImageCurlError, setCheckImageCurlError] = useState<boolean>(true)
  const [checkedImageCutError, setCheckedImageCutError] = useState<boolean>(true)
  const [checkedImageCurlError, setCheckedImageCurlError] = useState<boolean>(true);
  const [type, setType] = useState(0);
  const [countImageErrorCut, setCountImageErrorCut] = useState<number>(0);
  const [countImageErrorCurl, setCountImageErrorCurl] = useState(0);
  const [isNotEnoughCheckedCut, setIsNotEnoughCheckedCut] = useState<boolean>(true);
  const [isNotEnoughCheckedCurl, setIsNotEnoughCheckedCurl] = useState<boolean>(true);
  
  // lấy thông tin form chấm
  const getDefaultSCSCFormData = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response = await getDefaultSCSCForm();
      if (response) {
        dispatch(setDefaultSCSCForm(response.data))
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)

    }
  }
  // lấy chi tiết
  const fetchDetailData = async (params: any) => {
    if (loading) return
    const url = "/quality/check-scsc/list/admin"
    const isAdmin = urlAccess.find((i) => i.path === url)

    setLoading(true)
    try {
      const response: any = await getDetailSCSC(params);
      if (response.statusCode === 200) {
        if (response.data.scscCheckError) {
          const dataChecked = response.data.scscCheckError
          setForm({
            ...form,
            pointError: dataChecked.pointError,
            curlingPointError: dataChecked.curlingPointError,
            // thiếu mờ
            imageError: dataChecked.imageError,
            imageErrorCurling: dataChecked.imageErrorCurling,
            // cắt
            shapeId: dataChecked.shapeId,
            sharpnessId: dataChecked.sharpNessId,
            completionId: dataChecked.comPlatetionId,
            connectiveId: dataChecked.connectTiveId,
            creativeId: dataChecked.creativeId,
            // uốn
            hairTipId: dataChecked.hairTipId,
            hairRootId: dataChecked.hairRootId,
            hairWaveId: dataChecked.hairWavesId,
          })
          setPointCurl(dataChecked.curlingPointError)
          setPointCut(dataChecked.pointError)
          if (isAdmin) {
            setIsChecked(false)
            setCheckImageCutError(false)
            setCheckImageCurlError(false)
          } else {
            setIsChecked(true)
          }
        } else {
          setForm({
            id: 0,
            imageError: 0,
            totalPoint: 0,
            shapeId: 0,
            sharpnessId: 0,
            completionId: 0,
            pointError: 0,
            maxPointSharpId: 0,
            maxPointConnectiveId: 0,
            maxPointSharpNessId: 0,
            maxPointCompletionId: 0,
            workDate: "",
            curlingPointError: 0,
            hairTipId: 0,
            hairRootId: 0,
            hairWaveId: 0,
            imageErrorCurling: 0,
            staffId: 0,
            connectiveId: 0,
            creativeId: 0,
            maxPointCreative: 0,
            billId: 0
          })
          setPointCurl(0)
          setPointCut(0)
        }
        setData(response.data.staticScscCheck)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
    return [];
  };
  // Lấy điểm cất
  const getCheckedPointCut = (listChecked) => {
    const selectedIds = Object.values(listChecked).filter((id: any) => id !== 0 && listIdCutChecked.includes(id));
    const selectedIdCretive = Object.values(listChecked).filter((id: any) => id !== 0 && listIdCretive.includes(id));
    setPointCretive(selectedIdCretive.length)
    setPointCut(selectedIds.length)
  }
  // Lấy điểm uốn
  const getCheckedPoint = (listChecked) => {
    const selectedIds = Object.values(listChecked).filter((id: any) => id !== 0 && listIdCurlChecked.includes(id));
    setPointCurl(selectedIds.length)
  }

  const checkNull = () => {
    let keyText = ""
    for (const key in form) {
      if (form[key] === 0 && form.imageError === 0) {
        switch (key) {
          case "shapeId":
            keyText = "SHAPE (HÌNH KHỐI)"
            break;
          case "connectiveId":
            keyText = "CONNECTIVE (LIÊN KẾT)"
            break;
          case "sharpnessId":
            keyText = "SHARPNESS (SẮC NÉT)"
            break;
          case "completionId":
            keyText = "COMPLETION (HOÀN THIỆN)"
            break;
          default:
            break;
        }
      }
      if (data?.finalImage) {
        if (form[key] === 0 && form.imageErrorCurling === 0) {
          switch (key) {
            case "hairTipId":
              keyText = "Ngọn tóc"
              break;
            case "hairRootId":
              keyText = "Chân tóc"
              break;
            case "hairWaveId":
              keyText = "Sóng tóc"
              break;
            default:
              break;
          }
        }
      }
    }
    if (keyText.length > 0) {
      notification.error({
        message: 'Thông báo!',
        description: <>{keyText} chưa được chấm</>,
      });
      return true
    } else {
      return false
    }
  };
  // lưu chấm
  const saveCheckSCSC = async () => {
    if (loading) return
    setLoading(true)

    if(pointCut < 4 && form.imageError === 1 && isNotEnoughCheckedCut) {
      notification.error({
        message: 'Thông báo!',
        description: <>Bạn chưa chọn phương án xử lý với ảnh cắt</>,
      });
      setLoading(false)
      return
    }

    if(pointCurl < 3 && form.imageErrorCurling === 1 && isNotEnoughCheckedCurl) {
      notification.error({
        message: 'Thông báo!',
        description: <>Bạn chưa chọn phương án xử lý với ảnh uốn</>,
      });
      setLoading(false)
      return
    }

    if (checkNull()) {
      setLoading(false)
      return
    }
    
    if (pointCut < 4 && checkImageCutError && checkedImageCutError && form.imageError === 0 && countImageErrorCut === 0) {
      console.log('pointCut-checkImageCutError-checkImageCutError-checkedImageCutError-form.imageError'
          , pointCut, checkImageCutError, checkedImageCutError, form.imageError);
      
      notification.error({
        message: 'Thông báo!',
        description: <>Bạn chưa khoanh và nhận xét ảnh cắt</>,
      });
      setLoading(false)
      return
    }
    if (pointCurl < 3 && data?.finalImage && checkImageCurlError && checkedImageCurlError && form.imageErrorCurling === 0 && countImageErrorCurl === 0) {
      notification.error({
        message: 'Thông báo!',
        description: <>Bạn chưa khoanh và nhận xét ảnh uốn</>,
      });
      setLoading(false)
      return
    }
    let searchParams: any;
    if (type === 1) {
      searchParams = searchParamsSCSCAdmin;
    } else if (type === 2) {
      searchParams = searchParamsSCSC;
    }
    form.workDate = dayjs().format('DD-MM-YYYY')
    form.pointError = pointCut + pointCretive
    form.curlingPointError = pointCurl
    try {
      const response: any = await postSCSCCheck(form, searchParams);
      if (response.statusCode === 200) {
        setIsChecked(true);
        notification.success({
          message: 'Thông báo!',
          description: <>Chấm thành công</>,
        });
        if (searchParams && response.data.id) {
          setIsChecked(false)
          if (type === 1) {
            dispatch(
              setSearchParamsSCSCAdmin({
                ...searchParamsSCSCAdmin,
                PageIndex: response.data.page,
              })
            )
            navigate(`/quality/check-scsc/${response.data.id}`)
          } else if (type === 2) {
            dispatch(
              setSearchParamsSCSC({
                ...searchParamsSCSC,
                PageIndex: response.data.page,
              })
            )
            navigate(`/quality/check-scsc/${response.data.id}`)
          }
        }
      } else {
        console.log('error');
        notification.error({
          message: 'Thông báo!',
          description: <>{response.message}</>,
        });
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
      throw error;
    }
  };
  // check ảnh lỗi
  const errorImage = async (key) => {
    if (isChecked) return
    switch (key) {
      case 1:
        setForm({
          ...form,
          imageError: form.imageError === 1 ? 0 : 1,
          shapeId: 0,
          sharpnessId: 0,
          completionId: 0,
          connectiveId: 0,
          creativeId: 0,
        })
        break;
      case 2:
        setForm({
          ...form,
          imageErrorCurling: form.imageErrorCurling === 1 ? 0 : 1,
          hairTipId: 0,
          hairRootId: 0,
          hairWaveId: 0,
        })
        break;
      default:
        break;
    }
  }
  // Lấy thông tin chi tiết
  useEffect(() => {
    if (id && urlAccess) {
      fetchDetailData(id);
    }
  }, [id, urlAccess]);
  // set form chấm
  useEffect(() => {
    if (data) {
      setForm({
        ...form,
        id: data.id,
        billId: data.billId,
        staffId: Number(userInfo.userId)
      })
      setListDefaultNote(data.notes)
    }
  }, [data]);
  // Lấy form điểm chá
  useEffect(() => {
    if (defaultSCSCForm) {
      setForm({
        ...form,
        maxPointSharpId: defaultSCSCForm.shapMaxPoint,
        maxPointConnectiveId: defaultSCSCForm.connectiveMaxPoint,
        maxPointSharpNessId: defaultSCSCForm.sharpNessMaxPoint,
        maxPointCompletionId: defaultSCSCForm.completionMaxPoint,
        maxPointCreative: defaultSCSCForm.creativeMaxPoint,
      })
    }
  }, [defaultSCSCForm]);

  const onChangeForm = (key: string, value: any) => {
    setForm((form) => ({ ...form, [key]: value }));
  };

  useEffect(() => {
    if (!defaultSCSCForm) {
      getDefaultSCSCFormData()
    }
  }, []);

  useEffect(() => {
    if (pointCut || pointCurl) {
      if (pointCut + pointCretive < form.pointError) {
        setCheckImageCutError(true)
      } else if (pointCut + pointCretive > form.pointError && isChecked) {
        setCheckImageCutError(false)
      }
      if (pointCurl < form.curlingPointError) {
        setCheckImageCurlError(true)
      } else if (pointCurl > form.curlingPointError && isChecked) {
        setCheckImageCurlError(false)
      }
    }
  }, [pointCut, pointCurl]);

  useEffect(() => {
    if (searchParamsSCSCAdmin.Date) {
      setType(1)
    } else if (searchParamsSCSC.Date) {
      setType(2)
    } else {
      setType(0)
    }
  }, [searchParamsSCSCAdmin, searchParamsSCSC]);

  return (
    <div className='layout-detail'>
        <Spin spinning={loading}>
      <div className={'px-5 py-5 search-wrapper layout-detail__container'}>
      <div>
        <div className='wrapper mt-[14px]'>
          {data ?
            <div className='grid grid-cols-2 gap-5 text-base '>
              <div>
                <div style={{fontSize: '14px'}}>Bill Id: {data.billId}</div>
                <div style={{fontSize: '14px'}}>Dịch vụ: {data.serviceName}</div>
              </div>
              <div>
                <div style={{fontSize: '14px'}}>Ngày giờ cắt: {data.date}</div>
                <div style={{fontSize: '14px'}}>Kiểu tóc Stylist chọn: {data.hairStyle}</div>
              </div>
            </div>
            :
            null
          }
          {/* cắt */}
          <div className='Cắt'>
            <div className='flex flex-col mt-5'>
              <span className='text-xl font-bold md:text-3xl'>SCSC (Điểm: {pointCut + pointCretive}/4)</span>
            </div>
            <div className=''>
                <FormCheckImageCut
                  data={data}
                  form={form}
                  isCutImage={true}
                  setListDefaultNote={setListDefaultNote}
                  listDefaultNote={listDefaultNote}
                  isChecked={isChecked}
                  setCountImageErrorCut={setCountImageErrorCut} // xử lý check việc chưa chấm ảnh cắt
                  countImageErrorCut={countImageErrorCut}
                  setCountImageErrorCurl={setCountImageErrorCurl} //xử lý check việc chưa chấm ảnh uốn
                  countImageErrorCurl={countImageErrorCurl}
                  setCheckImageError={setCheckImageCutError}
                  setCheckedImageError={setCheckedImageCutError}
                  setIsNotEnoughCheckedCut={setIsNotEnoughCheckedCut}

                />
                <Button
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => errorImage(1)}
                  danger={form?.imageError === 1}
                  className={form?.imageError ?
                    'bg-white  max-md:hidden mt-5 text-red-600 border-red-600 '
                    :
                    'bg-white  max-md:hidden mt-5 text-[#1677ff] border-[#1677ff]'}
                >
                  <span className='text-sm'>Chụp thiếu ảnh/ mờ/ lệch/ sai thiết kế</span>
              </Button>
              </div>
              <div className=''>
                <FormCheckCut
                  getCheckedPoint={(list) => getCheckedPointCut(list)}
                  defaultSCSCForm={defaultSCSCForm}
                  onChangeForm={onChangeForm}
                  isErrorImage={form.imageError === 1}
                  form={form}
                  isChecked={isChecked}
                />
              </div>
          </div>

          {/* Uốn */}
          {data?.finalImage ?
            <div className='Uốn mt-5'>
              <div className='flex flex-col mt-5 md:mt-0'>
                <span className='text-xl font-bold md:text-3xl'>KCS Uốn (Điểm: {pointCurl}/3)</span>
              </div>
              <div className=''>
                  <FormCheckImageCut
                    data={data}
                    form={form}
                    isCutImage={false}
                    setListDefaultNote={setListDefaultNote}
                    listDefaultNote={listDefaultNote}
                    isChecked={isChecked}
                    setCheckImageError={setCheckImageCurlError}
                    setCheckedImageError={setCheckedImageCurlError}
                    setCountImageErrorCut={setCountImageErrorCut} // xử lý check việc chưa chấm ảnh cắt
                    countImageErrorCut={countImageErrorCut}
                    setCountImageErrorCurl={setCountImageErrorCurl} //xử lý check việc chưa chấm ảnh uốn
                    countImageErrorCurl={countImageErrorCurl}
                    setIsNotEnoughCheckedCurl={setIsNotEnoughCheckedCurl}
                  />
                  <Button
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    onClick={() => errorImage(2)}
                    danger={form?.imageErrorCurling === 1}
                    className={form?.imageErrorCurling ?
                      'bg-white md:hidden mt-5 text-red-600 border-red-600'
                      :
                      'bg-white md:hidden mt-5 text-[#1677ff] border-[#1677ff]'}
                  >
                    <span className='text-md'>Chụp thiếu ảnh/ mờ/ lệch/ sai thiết kế</span>
                  </Button>
                </div>
              <div className=''>
                <div className=''>
                  <FormCheckCurl
                    getCheckedPoint={(list) => getCheckedPoint(list)}
                    defaultSCSCForm={defaultSCSCForm}
                    onChangeForm={onChangeForm}
                    isErrorImage={form.imageErrorCurling === 1}
                    form={form}
                    isChecked={isChecked}
                  />
                </div>
                
              </div>
            </div>
            :
            null
          }

        </div>

        <div className='wrapper mt-[14px]'>
          <TextArea
            value={listDefaultNote ? listDefaultNote?.filter((item: any) => item?.error?.length > 0)?.map((item) => `Ảnh ${item.order}: Lỗi - ${item.error}`).join('. ') + '.' : ""}
            placeholder="Phương án xử lý khác"
            autoSize={{ minRows: 3, maxRows: 5 }}
            disabled
          />
        </div>
        <div className='wrapper mt-[14px] flex items-center justify-center'>
          <Button
            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50%" }}
            onClick={saveCheckSCSC}
            disabled={loading || isChecked}
            type={loading || isChecked ? "default" : "primary"}>
            Lưu
          </Button>
        </div>
      </div>
      </div>
     
    </Spin>
    </div>
  

  )
}

export default CheckSCSC

