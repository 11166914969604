import { Button, Form, Collapse, Table, Input, Modal, Select, Popconfirm, notification, InputNumber } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import type { CollapseProps } from 'antd'
import { SaveFilled, PlusCircleOutlined } from '@ant-design/icons'
import { check } from 'prettier'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import {
  getListProduct,
  getDataDetail,
  confirmRequest,
  saveProductInfo,
  getInventoryProduct,
  checkChangeInventoryProduct,
  changeInventoryProduct
} from './api'
import * as XLSX from 'xlsx'

export const RerquestOrderDetail = () => {
  const { id } = useParams()
  const [data, setData] = useState<any>()
  const [isModalOpenChangeInventory, setIsModalOpenChangeInventory] = useState(false)
  const [openPop, setOpenPop] = useState(false)
  const [openPopV2, setOpenPopV2] = useState(false)
  const [confirmLoadingPop, setConfirmLoadingPop] = useState(false)
  const [confirmLoadingPopV2, setConfirmLoadingPopV2] = useState(false)
  const [dataStatic, setDataStatic] = useState([])
  const [products, setProducts] = useState([])
  const [newProducts, setNewProducts] = useState([])
  const [open, setOpen] = useState(false)
  const [formAddProduct] = useForm()
  const [formChangeInventory] = useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openV2, setOpenV2] = useState(false)
  const [confirmLoadingV2, setConfirmLoadingV2] = useState(false)
  const [productReplace, setProductReplace] = useState()
  const [productBeReplace, setProductBeReplace] = useState()
  const [inventoryProductBeReplace, setInventoryProductBeReplace] = useState()
  const [inventoryProductAdd, setInventoryProductAdd] = useState()
  const [dataChangeNumber, setDataChangeNumber] = useState([])
  const [dataChangePrice, setDataChangePrice] = useState([])
  const [productType, setproductType] = useState()
  const [inventoryByProduct, setInventoryByProduct] = useState([])
  const [productName, setProductName] = useState(null)
  const [productId, setProductId] = useState(null)
  const [fromInventory, setFromInventory] = useState(null)
  let replaceProduct = 0
  const columns = [
    {
      title: 'Id sản phẩm',
      dataIndex: 'productId',
      key: 'productId'
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'productName',
      key: 'productName',
      with: '30%'
    },
    {
      title: 'Code',
      dataIndex: 'productCode',
      key: 'productCode'
    },
    {
      title: 'BarCode',
      dataIndex: 'productBarCode',
      key: 'productBarCode'
    },
    {
      title: 'Số lượng order dự kiến',
      dataIndex: 'numberOrderExpected',
      key: 'numberOrderExpected'
    },
    {
      title: 'Số lượng order thực tế',
      dataIndex: 'numberOrderReal',
      key: 'numberOrderReal',
      with: '8%',
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <InputNumber
              width={'50px'}
              disabled={data?.isConfirm}
              value={record.numberOrderReal}
              placeholder={record.numberOrderReal}
              onChange={(value) => {
                handleChangNumber(value, record)
              }}
            />
            <SaveFilled
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => {
                handleSaveNumberChange(record)
              }}
            />
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      with: '7%',
      render: (_, record) => {
        return data?.isConfirm === null ? (
          <div>
            <Button
              color='#40A9FF'
              onClick={() => {
                setProductReplace(null)
                setproductType(record.productType)

                const productGroup = products.find((c) => c.id == record.productId)
                if (productGroup) {
                  replaceProduct = productGroup.groupId
                  console.log('productGroup', productGroup)
                  console.log(productBeReplace+", "+record.productType+", "+
                    products.filter(
                      (c) =>
                        c.inventoryType === record.productType &&
                        c.id !== productGroup.id &&
                        (productType === '1' || c.groupId === replaceProduct)
                    )
                  )
                  setNewProducts(
                    products.filter(
                      (c) =>
                        c.inventoryType === record.productType &&
                        c.id !== productGroup.id &&
                        (productType === '1' || c.groupId === replaceProduct)
                    )
                  )
                }
                showModal(record.inventoryId, record.productId)
                console.log('newProducts', newProducts)
              }}
            >
              Trả bằng sản phẩm khác
            </Button>
            <Button
              style={{ marginTop: '5px' }}
              onClick={async () => {
                console.log(record)
                setProductName(record.productName)
                setProductId(record.productId)
                setFromInventory(record.inventoryId)
                const response = await getInventoryProduct(record.productId)
                const newData = response?.data?.map((item) => {
                  return {
                    label: item.name,
                    value: item.id
                  }
                })
                setInventoryByProduct(newData)
                setIsModalOpenChangeInventory(true)
              }}
            >
              Thay đổi nhà cung cấp
            </Button>
          </div>
        ) : (
          <div></div>
        )
      }
    }
  ]
  const columnStatics = [
    {
      title: 'Id sản phẩm',
      dataIndex: 'productId',
      key: 'productId',
      width: '8%'
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'productName',
      key: 'productName',
      width: '27%'
    },
    {
      title: 'Code',
      dataIndex: 'productCode',
      key: 'productCode',
      width: '18%'
    },
    {
      title: 'BarCode',
      dataIndex: 'productBarCode',
      key: 'productBarCode',
      width: '18%'
    },
    {
      title: 'Số lượng order dự kiến',
      dataIndex: 'numberOrderExpected',
      key: 'numberOrderExpected',
      width: '18%'
    },
    {
      title: 'Giá nhập (VNĐ)',
      dataIndex: 'productPrice',
      key: 'productPrice',
      width: '11%',
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <InputNumber
              width={'500px'}
              disabled={data?.isConfirm}
              placeholder={record.productPrice}
              defaultValue={record.productPrice}
              onChange={(e) => {
                handleChangPrice(record.productId, e)
              }}
            />
            <SaveFilled
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => {
                handleSavePrice(record.productId)
              }}
            />
          </div>
        )
      }
    }
  ]
  const itemStatics: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Sản phẩm bán',
      children: (
        <Table
          scroll={{ x: 500, y: 500 }}
          pagination={false}
          columns={columnStatics}
          dataSource={dataStatic.filter((c) => c.productType === '1').sort((a, b) => b.productId - a.productId)}
        />
      )
    },
    {
      key: '2',
      label: 'Sản phẩm vật tư',
      children: (
        <Table
          scroll={{ x: 500, y: 500 }}
          pagination={false}
          columns={columnStatics}
          dataSource={dataStatic.filter((c) => c.productType !== '1').sort((a, b) => b.productId - a.productId)}
        />
      )
    }
  ]
  const handleChangPrice = (productId, price) => {
    const dataNew = dataChangePrice
    const checkProduct = dataNew.find((c) => c.productId === productId)
    if (checkProduct) {
      checkProduct.price = price
      setDataChangePrice(dataNew)
    } else {
      dataNew.push({
        productId: productId,
        price: price
      })
    }
  }
  const handleSaveNumberChange = async (record) => {
    const dataCheck = data
    const dataChangeNumberNew = dataChangeNumber

    const checkNew = dataChangeNumberNew.find(
      (c) => c.productId === record.productId && c.inventoryId === record.inventoryId
    )
    if (checkNew) {
      const checkData = dataCheck.resultOrderDetails.find((c) => c.inventoryId === record.inventoryId)
      if (checkData) {
        const checkProduct = checkData.product.find((c) => c.productId === record.productId)

        if (checkProduct) {
          checkProduct.numberOrderReal = checkNew.numberOrderReal
          setData(dataCheck)
        }
      }
    }
    const checkProduct = dataChangeNumber.find(
      (c) => c.inventoryId === record.inventoryId && c.productId === record.productId
    )
    console.log(checkProduct)
    if (checkProduct) {
      const response: any = await saveProductInfo({
        requestId: id,
        inventoryId: checkProduct.inventoryId,
        numberOrderReal: checkProduct.numberOrderReal,
        productOld: checkProduct.productId
      })
      if (response?.statusCode === 200) {
        notification.success({
          message: 'Thông báo',
          description: response.message
        })
        handleGetDetail()
      } else {
        notification.error({
          message: 'Thông báo',
          description: response.message
        })
      }
    }
  }
  const handleChangNumber = (value, record) => {
    record.numberOrderReal = value
    const dataChangeNew = dataChangeNumber
    const check = dataChangeNew.find((c) => c.inventoryId === record.inventoryId && c.productId === record.productId)
    if (check) {
      check.numberOrderReal = value
    } else {
      dataChangeNew.push(record)
    }
    setDataChangeNumber(dataChangeNew)
  }
  const showModal = (inventoryId, id) => {
    const checkInventory = data.resultOrderDetails.find((c) => c.inventoryId == inventoryId)
    if (checkInventory) {
      console.log("checkInventory "+checkInventory)
      setInventoryProductBeReplace(inventoryId)
      const checkProduct = checkInventory.product.find((c) => c.productId == id)
      if (checkProduct) {
        console.log("checkProduct "+checkProduct)
        setProductBeReplace(id)
      }
    }

    setOpen(true)
  }
  const handleSavePrice = async (productId) => {
    const checkProduct = dataChangePrice.find((c) => c.productId == productId)
    if (checkProduct) {
      const response: any = await saveProductInfo({
        requestId: id,
        price: checkProduct.price,
        productOld: checkProduct.productId
      })
      if (response?.statusCode === 200) {
        notification.success({
          message: 'Thông báo',
          description: response.message
        })
        await handleGetDetail()
      } else {
        notification.error({
          message: 'Thông báo',
          description: response.message
        })
      }
    }
  }
  const showModalV2 = (id) => {
    formAddProduct.resetFields()
    setInventoryProductAdd(id)
    setOpenV2(true)
  }
  const handleOk = async () => {
    const dataCheck = data
    const inventoryProduct = dataCheck.resultOrderDetails.find((c) => c.inventoryId === inventoryProductBeReplace)
    if (inventoryProduct) {
      const checkProduct = products.find((c) => c.id == productReplace)
      const check = inventoryProduct.product.find((c) => c.productId == productBeReplace)
      const response: any = await saveProductInfo({
        requestId: id,
        inventoryId: inventoryProduct.inventoryId,
        numberOrderReal: check.numberOrderReal,
        productOld: productBeReplace,
        productnew: productReplace,
        price: checkProduct.price
      })
      if (response?.statusCode === 200) {
        notification.success({
          description: response.message,
          message: 'Thông báo'
        })
        handleGetDetail()
      } else {
        notification.error({
          description: response.message,
          message: 'Thông báo'
        })
      }
    }

    setOpen(false)
  }
  const handleOkV2 = async () => {
    setConfirmLoadingV2(true)
    const productId = formAddProduct.getFieldValue('productId')
    const dataClone = data
    const checkInventory = dataClone.resultOrderDetails.find((c) => c.inventoryId === inventoryProductAdd)
    const product = products.find((c) => c.id == productId)
    if (checkInventory && dataClone.resultOrderDetails.length > 0 && product) {
      const check = dataClone.resultOrderDetails.find((c) => c.inventoryId == inventoryProductAdd)
      if (check) {
        const response: any = await saveProductInfo({
          requestId: id,
          inventoryId: check.inventoryId,
          numberOrderReal: formAddProduct.getFieldValue('number'),
          productnew: productReplace,
          price: formAddProduct.getFieldValue('price')
        })
        setProductBeReplace(null)
        formAddProduct.setFieldValue('price', null)
        if (response?.statusCode === 200) {
          notification.success({
            description: response.message,
            message: 'Thông báo'
          })
          handleGetDetail()
        } else {
          notification.error({
            description: response.message,
            message: 'Thông báo'
          })
        }
      }
    }
    setTimeout(() => {
      setOpenV2(false)
      setConfirmLoadingV2(false)
    }, 2)
  }
  const handleCancel = () => {
    setProductBeReplace(null), setProductReplace(null), setproductType(null)
    setInventoryProductBeReplace(null)
    setOpen(false)
  }
  const handleCancelV2 = () => {
    setProductBeReplace(null), setProductReplace(null), setproductType(null)
    setInventoryProductBeReplace(null)
    setOpenV2(false)
  }
  const onChange = (value) => {
    setProductReplace(value)
  }
  const onChangeV2 = (value) => {
    setproductType(value)
    formAddProduct.setFieldValue('productId', null)
  }
  const onSearch = (value: string) => {
    console.log('search:', value)
  }
  const handleOkChangeInventory = async () => {
    const inventoryIdProduct = formChangeInventory.getFieldValue('inventoryId')
    const number = formChangeInventory.getFieldValue('number')
    const price = formChangeInventory.getFieldValue('price')
    if (price !== null && price > 0 && number !== null && number > 0) {
      const response: any = await checkChangeInventoryProduct({
        productId: productId,
        fromInventory: fromInventory,
        toInventory: inventoryIdProduct
      })
      console.log(response)
      if (response.data === false) {
        Modal.warning({
          title: 'Cảnh báo',
          content: response.message,
          okCancel: true,
          okText: 'Xác nhận',
          cancelText: 'Hủy bỏ',
          onCancel: () => {
            console.log('cancel')
          },
          onOk: async () => {
            const response: any = await changeInventoryProduct(
              {
                productId: productId,
                inventoryIdProduct: inventoryIdProduct,
                number: number,
                price: price,
                inventoryId: fromInventory
              },
              id
            )
            if (response.statusCode === 200) {
              Modal.success({
                title: 'Thông báo',
                content: 'Thành công'
              })
            } else {
              Modal.error({
                title: 'Thông báo',
                content: response.message
              })
            }
            handleGetDetail()
          }
        })
      } else {
        const response: any = await changeInventoryProduct(
          {
            productId: productId,
            inventoryIdProduct: formChangeInventory.getFieldValue('inventoryId'),
            number: formChangeInventory.getFieldValue('number'),
            price: formChangeInventory.getFieldValue('price'),
            inventoryId: fromInventory
          },
          id
        )
        if (response.statusCode === 200) {
          Modal.success({
            title: 'Thông báo',
            content: 'Thành công'
          })
        } else {
          Modal.error({
            title: 'Thông báo',
            content: response.message
          })
        }
        handleGetDetail()
      }

      setIsModalOpenChangeInventory(false)
      formChangeInventory.resetFields()
    } else {
      Modal.error({
        title: 'Thông báo',
        content: 'Bạn phải nhập đầy đủ số lượng và đơn giá.'
      })
    }
  }

  const handleCancelChangeInventory = () => {
    formChangeInventory.resetFields()
    setIsModalOpenChangeInventory(false)
  }
  const filterOption = (input: string, option?: { label: string; value: number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  const filterOptionV2 = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  const handleStatic = (requestOrder) => {
    const staticproductType = []
    requestOrder?.resultOrderDetails?.map((item) => {
      item.product?.map((a, index) => {
        const checkProduct = staticproductType.find((c) => c.productId == a.productId)
        if (checkProduct) {
          checkProduct.numberOrderExpected += a.numberOrderReal
        } else {
          staticproductType.push({
            id: index,
            productId: a.productId,
            productName: a.productName,
            productCode: a.productCode,
            productBarCode: a.productBarCode,
            numberOrderExpected: a.numberOrderReal,
            productPrice: a.productPrice,
            productType: a.productType
          })
        }
      })
    })
    setDataStatic(staticproductType)
  }
  const handleGetDataProduct = async () => {
    if (data) {
      const response: any = await getListProduct(data?.inventoryId)
      setProducts(response)
    }
  }
  const handleGetDetail = async () => {    
    const response: any = await getDataDetail(id)
    if (response?.statusCode === 200) {
      setData(response?.data)
      // notification.success({
      //   description: 'Thông báo',
      //   message: response.message
      // })
    } else {
      notification.error({
        description: response.message,
        message: 'Thông báo'
      })
      setProductBeReplace(null)
    }
  }
  const showPopconfirm = () => {
    setOpenPop(true)
    setOpenPopV2(false)
  }
  const showPopconfirmV2 = () => {
    setOpenPop(false)
    setOpenPopV2(true)
  }
  const handleOkPop = async () => {
    setConfirmLoadingPop(true)
    data.isConfirm = false
    const response: any = await confirmRequest({
      id: data.id,
      isConfirm: false,
      ProductConfirm: data.resultOrderDetails
    })
    setOpenPop(false)
    setConfirmLoadingPop(false)
    if (response?.statusCode === 200) {
      notification.success({
        message: 'Thông báo',
        description: response.message
      })
    } else {
      notification.error({
        message: 'Thông báo',
        description: response.message
      })
    }
  }
  const handleOkPopV2 = async () => {
    setConfirmLoadingPopV2(true)
    data.isConfirm = true
    const response: any = await confirmRequest(data)
    if (response?.statusCode === 200) {
      notification.success({
        message: 'Thông báo',
        description: response.message
      })
    } else {
      notification.error({
        message: 'Thông báo',
        description: response.message
      })
    }

    setOpenPopV2(false)
    setConfirmLoadingPopV2(false)
  }
  const exportToExcel = () => {
    try {
      const ws = XLSX.utils.json_to_sheet(
        dataStatic.map((c) => {
          return {
            'Id kho NCC': data.inventoryId,
            'Tên kho NCC': data.inventoryName,
            'ID sản phẩm': c.productId,
            'Tên sản phẩm': c.productName,
            'Barcode sản phẩm': c.barCode,
            'Mã code sản phẩm': c.code,
            'Số lượng order dự kiến': c.numberOrderExpected,
            'Giá nhập': c.productPrice
          }
        })
      )
      const dataSheet2 = []
      data?.resultOrderDetails?.map((item) => {
        item.product.map((c) => {
          dataSheet2.push({
            'ID kho NCC': data.inventoryId,
            'Tên kho NCC': data.inventoryName,
            'ID kho 30Shine': item.inventoryId,
            'Tên kho 30Shine': item.inventoryName,
            'ID sản phẩm': c.productId,
            'Tên sản phẩm': c.productName,
            'Barcode sản phẩm': c.productBarCode,
            'Mã code sản phẩm': c.productCode,
            'Số lượng order dự kiến': c.numberOrderExpected,
            'Số lượng order thực tế': c.numberOrderReal,
            'Giá nhập': c.productPrice
          })
        })
      })
      const ws2 = XLSX.utils.json_to_sheet(dataSheet2)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Tổng hợp')
      XLSX.utils.book_append_sheet(wb, ws2, 'Data theo salon')
      const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `danh_sach_don_order${id}_.xlsx`
      document.body.appendChild(a)
      a.click()
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Thông báo',
        description: 'Có lỗi xảy ra.'
      })
    }
  }
  const handleCancelPop = () => {
    setOpenPop(false)
  }
  const handleCancelPopV2 = () => {
    setOpenPopV2(false)
  }
  useEffect(() => {
    handleStatic(data)
  }, [data])
  useEffect(() => {
    handleGetDataProduct()
    handleGetDetail()
  }, [])
  useEffect(() => {
    handleGetDataProduct()
  }, [data])
  return (
    <div className='w-full px-5 py-5 search-wrapper'>
      <Modal
        title='Chọn sản phẩm thay thế'
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className='custom-modal'
      >
        <Form>
          <Form.Item label='Chọn sản phẩm'>
            <Select         
              value={productReplace}     
              showSearch
              placeholder='Chọn một sản phẩm'
              optionFilterProp='children'
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={newProducts.map((item) => {
                return { label: item.name, value: item.id }
              })}
            />
          </Form.Item>
        </Form>
        <style>{`
          .custom-modal .ant-modal-title {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </Modal>
      <Modal
        title='Thêm sản phẩm'
        open={openV2}
        onOk={handleOkV2}
        confirmLoading={confirmLoadingV2}
        onCancel={handleCancelV2}
        className='custom-modal'
      >
        <Form form={formAddProduct}>
          <Form.Item label='Loại sản phẩm' name='typeProduct'>
            <Select
              showSearch
              placeholder='Chọn'
              optionFilterProp='children'
              onChange={onChangeV2}
              onSearch={onSearch}
              filterOption={filterOptionV2}
              options={[
                {
                  value: '1',
                  label: 'Sản phẩm bán'
                },
                {
                  value: '2',
                  label: 'Sản phẩm vật tư'
                }
              ]}
            />
          </Form.Item>
          <Form.Item label='Chọn sản phẩm' name='productId'>
            <Select
              showSearch
              placeholder='Chọn một sản phẩm'
              optionFilterProp='children'
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={products
                .filter((c) => c.inventoryType === productType)
                .map((item) => {
                  return { label: item.name, value: item.id }
                })}
            />
          </Form.Item>
          <Form.Item label='Số lượng' name='number'>
            <Input width={'80%'} />
          </Form.Item>
          <Form.Item label='Giá nhập' name='price'>
            <Input
              disabled={dataStatic.find((c) => c.productId === productReplace)}
              placeholder={dataStatic.find((c) => c.productId === productReplace)?.productPrice}
            />
          </Form.Item>
        </Form>
        <style>{`
          .custom-modal .ant-modal-title {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </Modal>

      <Modal
        title='Đổi nhà cung cấp'
        open={isModalOpenChangeInventory}
        onOk={handleOkChangeInventory}
        onCancel={handleCancelChangeInventory}
        className='custom-modal'
      >
        <Form form={formChangeInventory}>
          <Form.Item label='Sản phẩm' name='productName'>
            {productName}
          </Form.Item>
          <Form.Item label='Nhà cung cấp' name='inventoryId'>
            <Select
              showSearch
              placeholder='Chọn'
              optionFilterProp='children'
              onChange={onChangeV2}
              onSearch={onSearch}
              filterOption={filterOptionV2}
              options={inventoryByProduct}
            />
          </Form.Item>
          <Form.Item label='Số lượng' name='number'>
            <Input width={'80%'} />
          </Form.Item>
          <Form.Item label='Giá nhập' name='price'>
            <Input />
          </Form.Item>
        </Form>
        <style>{`
          .custom-modal .ant-modal-title {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </Modal>
      <div style={{ display: 'flex' }}>
        <h2 className='text-2xl font-semibold mb-3'>Chi tiết đơn {id}</h2>

        <div style={{ marginLeft: '70%' }}>
          <Button style={{ marginRight: '10px' }} type='primary' onClick={exportToExcel}>
            Xuất excel
          </Button>
          {data?.isConfirm === null && (
            <>
              <Popconfirm
                title='Thông báo'
                description={`Xác nhận không duyệt đơn Id ${id}`}
                open={openPop}
                onConfirm={handleOkPop}
                okButtonProps={{ loading: confirmLoadingPop }}
                onCancel={handleCancelPop}
              >
                <Button style={{ marginRight: '10px' }} onClick={showPopconfirm}>
                  Không duyệt
                </Button>
              </Popconfirm>
              <Popconfirm
                title='Thông báo'
                description={`Xác nhận duyệt đơn Id ${id}`}
                open={openPopV2}
                onConfirm={handleOkPopV2}
                okButtonProps={{ loading: confirmLoadingPopV2 }}
                onCancel={handleCancelPopV2}
              >
                <Button type='primary' onClick={showPopconfirmV2}>
                  Duyệt
                </Button>
              </Popconfirm>
            </>
          )}
        </div>
      </div>
      <Form>
        <div>
          <Form.Item label='Kho NCC'>
            <span>{data?.inventoryName}</span>
          </Form.Item>
          <Form.Item label='Ngày ra đơn duyệt'>
            <span>{data?.createdDate && dayjs(data?.createdDate).format('DD/MM/YYYY')}</span>
          </Form.Item>
          <Form.Item label='Ngày duyệt đơn'>
            <span>{data?.dateConfirm && dayjs(data?.dateConfirm).format('DD/MM/YYYY')}</span>
          </Form.Item>
          <Form.Item label='Trạng thái đơn'>
            <span>
              {data?.isConfirm === null ? 'Chưa duyệt' : data?.isConfirm == true ? 'Đã duyêt' : 'Không duyệt'}
            </span>
          </Form.Item>
        </div>
      </Form>
      <h2 className='text-xl font-semibold mb-3'>Tổng hợp sản phẩm</h2>
      <Collapse items={itemStatics} defaultActiveKey={['1', '2']} />

      {data?.resultOrderDetails?.length > 0 &&
        data?.resultOrderDetails?.map((a) => {
          return (
            <div key={a.id} className='py-3'>
              <h2 className='text-xl font-semibold mb-3'>{a.inventoryName}</h2>
              <Collapse
                defaultActiveKey={['1', '2']}
                items={[
                  {
                    key: '1',
                    label: 'Sản phẩm bán',
                    children: (
                      <Table
                        scroll={{ x: 500, y: 500 }}
                        pagination={false}
                        columns={columns}
                        dataSource={a.product
                          .filter((c) => c.productType === '1')
                          .sort((a, b) => b.productId - a.productId)}
                      />
                    )
                  },
                  {
                    key: '2',
                    label: 'Sản phẩm vật tư',
                    children: (
                      <Table
                        scroll={{ x: 500, y: 500 }}
                        pagination={false}
                        columns={columns}
                        dataSource={a.product
                          .filter((c) => c.productType !== '1')
                          .sort((a, b) => b.productId - a.productId)}
                      />
                    )
                  }
                ]}
              />
              {data?.isConfirm === null && (
                <Button
                  color='#40A9FF'
                  onClick={() => {
                    showModalV2(a.id)
                  }}
                >
                  <PlusCircleOutlined /> Thêm sản phẩm
                </Button>
              )}
            </div>
          )
        })}
    </div>
  )
}
