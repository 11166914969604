/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal, Spin } from "antd";
import { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive";
import CheckError from "./modalCheckError/checkError";

interface Props {
    data: any,
    isCutImage: boolean,
    setListDefaultNote: any,
    listDefaultNote: any,
    isChecked: boolean,
    setCheckImageError: any,
    setCheckedImageError: any,
    form: any,
    setCountImageErrorCut: any,
    countImageErrorCut: number,
    setCountImageErrorCurl: any,
    countImageErrorCurl: number,
    setIsNotEnoughCheckedCut?: any,
    setIsNotEnoughCheckedCurl?: any,
}

const FormCheckImageCut = (props: Props) => {
    const { 
        data, 
        isCutImage, 
        setListDefaultNote, 
        listDefaultNote, 
        isChecked, 
        setCheckImageError, 
        setCheckedImageError, 
        form, 
        setCountImageErrorCut, 
        countImageErrorCut,
        setCountImageErrorCurl,
        countImageErrorCurl,
        setIsNotEnoughCheckedCurl,
        setIsNotEnoughCheckedCut
     } = props
    const [listDefaultImage, setListDefaultImage] = useState<any>()
    const [listDefaultImageCurl, setListDefaultImageCurl] = useState<any>()
    // const [listDefaultNote, setListDefaultNote] = useState<any>()
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedImageOrder, setSelectedImageOrder] = useState<number>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true)
    const [checkDone, setCheckDone] = useState<boolean>(false)

    const showModal = (img: string, index: number) => {
        if (isChecked) return
        setSelectedImage(img)
        setSelectedImageOrder(index + 1)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (data) {
            setListDefaultImage(data?.image);
            setListDefaultImageCurl(data?.finalImage)
            setLoading(false)
        }
    }, [data]);

    useEffect(() => {
        setIsModalOpen(false);
        if (selectedImageOrder < 4) {
            OpenNext()
        }else{
            setCheckDone(false)
        }

    }, [listDefaultImage, listDefaultImageCurl, listDefaultNote]);

    const OpenNext = () => {
        if (loading) return
        setLoading(true)
        try {
            if (checkDone && selectedImage && selectedImageOrder < 4) {
                setSelectedImage(listDefaultImage[selectedImageOrder])
                setSelectedImageOrder(selectedImageOrder + 1)
                setIsModalOpen(true);
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
            throw error
        }
    }

    return (
        <Spin spinning={loading}>
            <div className={'grid grid-cols-4 gap-1 mt-5'}>
                {isCutImage ?
                    <>
                        {listDefaultImage?.map((item: any, index: number) => (
                            <div key={index} onClick={() => showModal(item, index)}>
                                <img src={item} alt="cut" />
                            </div>
                        ))}
                    </>
                    :
                    <div onClick={() => showModal(listDefaultImageCurl, 4)}>
                        <img src={listDefaultImageCurl} alt="curl" />
                    </div>
                }
                <Modal title={isCutImage ? "Check lỗi cắt" : "Check lỗi uốn"} destroyOnClose={true} open={isModalOpen} style={{ top: 20 }} zIndex={10000} footer={false} onCancel={handleCancel}>
                    <CheckError
                        selectedImage={selectedImage}
                        selectedImageOrder={selectedImageOrder}
                        setListDefaultImage={setListDefaultImage}
                        setListDefaultNote={setListDefaultNote}
                        setSelectedImage={setSelectedImage}
                        setListDefaultImageCurl={setListDefaultImageCurl}
                        listDefaultImage={listDefaultImage}
                        ListImageNote={listDefaultNote}
                        isCutImage={isCutImage}
                        imageCurl={listDefaultImageCurl}
                        billId={data?.billId}
                        setCheckImageError={setCheckImageError}
                        setCheckDone={setCheckDone}
                        checkDone={checkDone}
                        setCheckedImageError={setCheckedImageError}
                        imageError={form?.imageError}
                        imageErrorCurling={form?.imageErrorCurling}
                        setCountImageErrorCut={setCountImageErrorCut}
                        countImageErrorCut={countImageErrorCut}
                        setCountImageErrorCurl={setCountImageErrorCurl}
                        countImageErrorCurl={countImageErrorCurl}
                        setIsNotEnoughCheckedCut={setIsNotEnoughCheckedCut}
                        setIsNotEnoughCheckedCurl={setIsNotEnoughCheckedCurl}
                    />
                </Modal>
            </div>
        </Spin>
    )
}

export default FormCheckImageCut

