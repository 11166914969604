import { author, author_catalog } from './config'
import axios from 'axios'
import Cookies from 'js-cookie'
import { catalog } from 'pages/Products/api/config'

interface paramsProps {
  page: number
  pageSize: number
}
export const getListServiceSalon = (params: paramsProps) => {
  return author.get(`/api/salon-service/list`, {
    params: params
  })
}

export const getListServiceMemberService = (serviceId, params) => {
  return catalog.get(`/v1/member-service/service/${serviceId}`, {
    params: params
  })
}
export const getListServiceMemberSalon = (salonId, params) => {
  return catalog.get(`/v1/member-service/${salonId}`, {
    params: params
  })
}
export const updateStatus = (serviceId, body) => {
  return catalog.put(`/v1/service/publish/${serviceId}`, body)
}
export const updateMemberServices = (body) => {
  return catalog.post(`/v1/member-service`, body)
}
export const getListService = (serviceId, params) => {
  console.log(params, 'params')
  return catalog.get(`/v1/salon-service/service/${serviceId}`, { params: params })
}
export const getListServiceSalonV2 = (params) => {
  return catalog.get(`/v1/salon-service/${params.salonId}`, { params: params })
}
export const getListServices = (params: paramsProps) => {
  return author.get(`/api/v1/service-manage/services-manage-list`, {
    params: params
  })
}

export const updateServices = (servicesId: string, body: any) => {
  return catalog.put(`/v1/service/${servicesId}`, body)
}

export const createServices = (body: any) => {
  return catalog.post(`/v1/service`, body)
}
export const createSalonServices = (body) => {
  return catalog.post(`/v1/salon-service`, body)
}
const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken') // Thay đổi key này nếu cần
}

export const downloadExcel = async (params: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie()
  const url = process.env.REACT_APP_API_WEBFORM_ERP + `/api/v1/service-manage/services-manage-list/export?${params}`

  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `export_data.xlsx`

      document.body.appendChild(a)
      a.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error)
    })
}

export const GetListServices = () => {
  return author.get(`/api/v1/list-select/services-list`)
}
export const GetListServicesV2 = () => {
  return catalog.get(`/v1/service?pageSize=0&pageIndex=0`)
}
export const getDetailServices = (salonId: string) => {
  return author.get(`/api/v1/service-manage/${salonId}/detail`)
}

export const getListTreeServices = () => {
  return author.get(`/api/v1/list-select/category-list`)
}
export const getListTreeServicesV2 = () => {
  return catalog.get(`/v1/category`)
}

// API mới catalog 17/11/2023
export const getListServiceCatalog = (params: paramsProps) => {
  return author_catalog.get(`/v1/service`, {
    params: params
  })
}
export const GenCodeService = () => {
  return author_catalog.post(`/v1/service/generate-code`)
}
export const getDetailServiceCatalog = (id: string) => {
  return author_catalog.get(`/v1/service/${id}`)
}
interface salonserviceDTO {
  salonId?: number
  serviceId?: number
  price?: number
  isCheck?: boolean
}
export const updateSalonServiceCatalog = (body: salonserviceDTO[]) => {
  return author_catalog.post(`/v1/salon-service`, body)
}

interface paramsPropsListServicesFlowSalon {
  serviceIds?: string
  salonIds?: string
}
export const getListServicesFlowSalon = (params: paramsPropsListServicesFlowSalon, salonId: number) => {
  return author_catalog.get(`/v1/salon-service/${salonId}`, {
    params: params
  })
}
export const getListSalonFlowService = (params: paramsPropsListServicesFlowSalon, serviceId: number) => {
  return author_catalog.get(`/v1/salon-service/service/${serviceId}`, {
    params: params
  })
}


//lay ds department tu db catalog
export const getDepartments= () => {
  return catalog.get(`/v1/departments`)
}